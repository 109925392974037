import React, { useEffect, useState } from 'react';

import { Layout, Breadcrumb, Spin, Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const { Content } = Layout;

import { HANDLER_ERRORS } from 'errors';
import { Api } from 'client/lib/api';
import { useModalContext } from 'context/ModalContext';
import ParcelasList from 'components/ParcelasList';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function ParcelasPage() {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  const { dataModal } = useModalContext();

  async function getParcelas(page, limit, searchTerms) {
    setLoading(true);
    const filters = { ...searchTerms, page: page };
    const carteira = localStorage.getItem('carteira');

    if (carteira !== '0') {
      filters.carteira = carteira;
    }
    delete filters['Data vencimento'];
    await Api()
      .parcela.list(filters)
      .then((response) => {
        setData(response);
      })
      .catch((err) => {
        console.error(err);
        message.error(HANDLER_ERRORS.PAR.LIST_ERROR);
      });
    setLoading(false);
  }

  useEffect(() => {
    getParcelas();
  }, []);

  return (
    <Layout>
      {dataModal?.visible && <Modal {...dataModal}>{dataModal.children}</Modal>}
      <Breadcrumb>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Lista de parcelas</Breadcrumb.Item>
      </Breadcrumb>
      <Content className="site-layout-background">
        <>
          {loading ? (
            <div className="loadingWrapper">
              <Spin indicator={antIcon} />
              <p>Carregando</p>
            </div>
          ) : (
            data && <ParcelasList data={data} onChange={getParcelas} />
          )}
        </>
      </Content>
    </Layout>
  );
}

export default ParcelasPage;
