import React from 'react';

import {
  FileTextOutlined,
  LikeOutlined,
  UsergroupAddOutlined,
  OrderedListOutlined,
  ControlOutlined
} from '@ant-design/icons';
import { CONSTANTS } from '../../../context/Auth/constants';

type MenuMapType = {
  [key: string]: {
    key: string;
    path: any;
    label: string;
    icon: React.ReactNode;
    external: boolean;
  };
};

export const menuMap: MenuMapType = {
  contratos: {
    key: CONSTANTS.PERMISSIONS.CONTRACTS,
    path: '/contratos',
    label: 'Contratos',
    icon: <FileTextOutlined />,
    external: false
  },
  acordos: {
    key: CONSTANTS.PERMISSIONS.AGREEMENTS,
    path: '/acordos',
    label: 'Acordos',
    icon: <LikeOutlined />,
    external: false
  },
  parcelas: {
    key: CONSTANTS.PERMISSIONS.INSTALMENTS,
    path: '/parcelas',
    label: 'Parcelas',
    icon: <OrderedListOutlined />,
    external: false
  },
  usuarios: {
    key: CONSTANTS.PERMISSIONS.USERS,
    path: '/system/admin/settings/users',
    label: 'Usuários',
    icon: <UsergroupAddOutlined />,
    external: true
  },
  perfis: {
    key: CONSTANTS.PERMISSIONS.PROFILES,
    path: '/system/admin/settings/roles',
    label: 'Perfis',
    icon: <ControlOutlined />,
    external: true
  }
};
