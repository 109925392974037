import BaseHttpClient from '../http/AxiosHttpClient';

export class AuthApi {
  private readonly _httpClient: BaseHttpClient;

  public constructor() {
    this._httpClient = new BaseHttpClient();
  }

  public async getCurrentUser(): Promise<any> {
    return this._httpClient.get(`system/api/user`);
  }

  public async getMenuPermissions(): Promise<any> {
    return this._httpClient.get(`api/1.0/menu`);
  }

  public async logout(): Promise<any> {
    return this._httpClient.post(`system/logout`, null);
  }
}
