import { Menu as AntdMenu } from 'antd';
import BaixarPagamento, {
  BaixarPagamentoHandler
} from 'components/AcordoDetails/templates/BaixarPagamento';
import CancelPagamento, {
  CancelPagamentoHandler
} from 'components/AcordoDetails/templates/CancelPagamento';
import EstornarPagamento, { EstornarPagamentoHandler } from 'components/AcordoDetails/templates/EstornarPagamento';
import LiquidarPagamento, {
  LiquidarPagamentoHandler
} from 'components/AcordoDetails/templates/LiquidarPagamento';
import { DataModalType, useModalContext } from 'context/ModalContext';
import { useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

export type PagamentoMenuProps = {
  dataPagamento: any;
  getData: (params: any) => void;
  getDataParams: any;
};

export const PagamentoMenu: React.FC<PagamentoMenuProps> = ({ dataPagamento, getData, getDataParams }) => {
  const options = [];
  const { setDataModal } = useModalContext();


  const cancelPagamentoTemplateRef = useRef<CancelPagamentoHandler>(null);
  const estornarPagamentoTemplateRef = useRef<EstornarPagamentoHandler>(null);
  const liquidarPagamentoTemplateRef = useRef<LiquidarPagamentoHandler>(null);
  const baixarPagamentoTemplateRef = useRef<BaixarPagamentoHandler>(null);

  useEffect(() => {
    let isSubscribed = true;
    return () => {
      isSubscribed = false;
    };
  }, []);


  const params: [any] = getDataParams;

 
  function handleCancelPagamentoClick() {
    const data: DataModalType = {
      title: `Cancelar pagamento - Pagamento: ${dataPagamento.id}`,
      children: (
        <CancelPagamento
          ref={cancelPagamentoTemplateRef}
          data={{ ...dataPagamento}}
        />
      ),
      visible: true,
      onOk: () => {
        cancelPagamentoTemplateRef?.current?.submitForm();
      },
      onCancel: () => setDataModal({ visible: false, children: null }),
      onFinish: () => {
        getData(...params);
      }
    };
    setDataModal(data);
  }

  function handleLiquidarPagamentoClick() {
    const data: DataModalType = {
      title: `Liquidar Pagamento - Pagamento: ${dataPagamento.id}`,
      children: (
        <LiquidarPagamento
          ref={liquidarPagamentoTemplateRef}
          data={{ ...dataPagamento }}
        />
      ),
      visible: true,
      onOk: () => {
        liquidarPagamentoTemplateRef?.current?.submitForm();
      },
      onCancel: () => setDataModal({ visible: false, children: null }),
      onFinish: () => {
        getData(...params);
      }
    };
    setDataModal(data);
  }

  function handleBaixarPagamentoClick() {
    const data: DataModalType = {
      title: `Baixar Pagamento - Pagamento: ${dataPagamento}`,
      children: (
        <BaixarPagamento
          ref={baixarPagamentoTemplateRef}
          data={{ ...dataPagamento}}
        />
      ),
      visible: true,
      onOk: () => {
        baixarPagamentoTemplateRef?.current?.submitForm();
      },
      onCancel: () => setDataModal({ visible: false, children: null }),
      onFinish: () => {
        getData(...params);
      }
    };
    setDataModal(data);
  }


  function handleEstornarCobrancaClick() {
    const data: DataModalType = {
      title: `Cancelar cobrança - Pagamento: ${dataPagamento.id}`,
      children: (
        <EstornarPagamento
          ref={estornarPagamentoTemplateRef}
          data={{ ...dataPagamento}}
        />
      ),
      visible: true,
      onOk: () => {
        estornarPagamentoTemplateRef?.current?.submitForm();
      },
      onCancel: () => setDataModal({ visible: false, children: null }),
      onFinish: () => {
        getData(...params);
      }
    };
    setDataModal(data);
  }

  if (dataPagamento.actions?.includes('cancelar_pagamento')) {
    options.push({
      label: ' Cancelar pagamento',
      key: uuid(),
      onClick: handleCancelPagamentoClick
    });
  }
console.log(dataPagamento);
  if (dataPagamento.actions?.includes('liquidar_pagamento')) {
    options.push({
      label: ' Colocar pagamento como Liquidado',
      key: uuid(),
      onClick: handleLiquidarPagamentoClick
    });
  }

  if (dataPagamento.actions?.includes('baixar_pagamento')) {
    options.push({
      label: ' Colocar pagamento como Baixado',
      key: uuid(),
      onClick: handleBaixarPagamentoClick
    });
  }

  if (dataPagamento.actions?.includes('estornar_pagamento')) {
    options.push({
      label: 'Estornar pagamento',
      key: uuid(),
      onClick: handleEstornarCobrancaClick
    });
  }

  return options.length ? <AntdMenu items={options}></AntdMenu> : null;
};
