import React from 'react';
import PropTypes from 'prop-types';

StatusAcordo.propTypes = {
  status: PropTypes.number
};

function StatusAcordo({ status }) {
  let label = '';
  switch (status) {
    case 1:
      label = 'Simulação';
      break;
    case 2:
      label = 'Enviado';
      break;
    case 3:
      label = 'Aceito';
      break;
    case 4:
      label = 'Quebrado';
      break;
    case 5:
      label = 'Liquidado';
      break;
    default:
      break;
  }

  return <b>{label}</b>;
}

export default StatusAcordo;
