import styled from 'styled-components';

export const Wrapper = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
  }
`;

export const Navbar = styled.nav``;
