import { Layout } from 'antd';
const { Content, Footer, Sider } = Layout;
import { Header } from './components/Header';
import MenuDashboard from './components/MenuDashboard';
import Routes from './Routes';

const App = () => {
  return (
    <Layout>
      <Header />
      <Content style={{ padding: '0 20px' }}>
        <Layout className="site-layout-background" style={{ padding: '0px 0' }}>
          <Sider className="site-layout-background no-print" width={200}>
            <MenuDashboard />
          </Sider>
          <Content style={{ padding: '0px 15px 40px', minHeight: 280 }}>
            <Routes />
          </Content>
        </Layout>
      </Content>
      <Footer style={{ textAlign: 'center' }}>
        <p>
          {process.env.REACT_APP_NAME} ©2021 Created by Acrux/CARJ
          <br />
          {process.env.REACT_APP_VERSION}
        </p>
      </Footer>
    </Layout>
  );
};

export default App;
