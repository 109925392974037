import QueryString from 'qs';
import BaseHttpClient from '../http/BaseHttpClient';

type ParamType = {
  [key: string]: any;
};

export class ParcelaApi {
  private readonly _httpClient: BaseHttpClient;

  public constructor() {
    this._httpClient = new BaseHttpClient();
  }

  public async list(params?: ParamType): Promise<any> {
    if (params) {
      const paramsQs = QueryString.stringify(params);
      return this._httpClient.post(`/api/1.0/parcela/listar?${paramsQs}`, null);
    }
    return this._httpClient.post(`/api/1.0/parcela/listar`, null);
  }

  public async listPayments(id: string): Promise<any> {
      return this._httpClient.get(`/api/1.0/parcela/pagamentos/${id}`);
  }


}
