import { Form, message } from 'antd';
import { Api } from 'client/lib/api';
import { useModalContext } from 'context/ModalContext';
import { forwardRef, useCallback, useImperativeHandle } from 'react';

export type CancelPagamentoHandler = {
  submitForm: () => void;
};

export type CancelPagamentoProps = {
  data: any;
};

const CancelPagamento: React.ForwardRefRenderFunction<
  CancelPagamentoHandler,
  CancelPagamentoProps
> = ({ data }, ref) => {
  const { hideModal, dataModal, onLoading, abortLoading } = useModalContext();
  const [form] = Form.useForm();

  const submitForm = useCallback(() => {
    if (data) {
      const formValues = form.getFieldsValue();
      const body = {
        force: formValues.force
      };

      onLoading();
      Api()
        .pagamento.cancelPagamento(data.id, body)
        .then(() => {
          message.success('Pagamento cancelado!');
          if (dataModal.onFinish) {
            dataModal?.onFinish();
          }
          hideModal();
        })
        .catch(() => abortLoading());
    }
  }, []);

  useImperativeHandle(ref, () => {
    return {
      submitForm
    };
  });

  return (
    <Form form={form}>
      Têm certeza que deseja cancelar o Pagamento?
    </Form>
  );
};

export default forwardRef(CancelPagamento);
