import { Checkbox, Form, message } from 'antd';
import { Api } from 'client/lib/api';
import { CancelCobranca as ICancelCobranca } from 'client/lib/cobranca/models';
import { useModalContext } from 'context/ModalContext';
import { forwardRef, useCallback, useImperativeHandle } from 'react';
import { IParcela } from '../models';

export type CancelCobrancaHandler = {
  submitForm: () => void;
};

export type CancelCobrancaProps = {
  data: IParcela;
};

const CancelCobranca: React.ForwardRefRenderFunction<CancelCobrancaHandler, CancelCobrancaProps> = (
  { data },
  ref
) => {
  const [form] = Form.useForm();
  const { hideModal, dataModal, onLoading, abortLoading } = useModalContext();

  const submitForm = useCallback(() => {
    const formValues = form.getFieldsValue();
    const body: ICancelCobranca = {
      force: formValues.force
    };

    onLoading();

    Api()
      .cobranca.cancelCobranca(data.cobranca[0].id, body)
      .then(() => {
        message.success('Cobrança cancelada com sucesso!');
        if (dataModal.onFinish) {
          dataModal?.onFinish();
        }
        hideModal();
      })
      .catch(() => abortLoading());
  }, [form]);

  useImperativeHandle(ref, () => {
    return {
      submitForm
    };
  });

  return (
    <Form form={form}>
      Têm certeza que deseja Cancelar a Cobrança ?
    </Form>
  );
};

export default forwardRef(CancelCobranca);
