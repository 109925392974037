import { Popconfirm } from 'antd';
import moment from 'moment';
import React from 'react';
import * as S from './styles';

import { Button, Col, Descriptions, Divider, Dropdown, Row, Select, Space, Table } from 'antd';
import { v4 as uuid } from 'uuid';

const { Option } = Select;

import ContractClient from 'client/api';
import { Api } from 'client/lib/api';
import { useModalContext } from 'context/ModalContext';
import { Modal } from '../Modal';
import StatusAcordo from '../StatusAcordo';
import { ActionMenu } from './components/ActionMenu';

import { RenderBoletoActionButtons } from 'components/helpers/RenderBoletoActionButtons';

const client = new ContractClient();
const dateFormat = 'DD/MM/YYYY';

export type AcordoDetailProps = {
  acordo: any;
  getAcordo: (id: any) => void;
  salvarAcordo: (id: number) => void;
};

type ColumnType = {
  title: string;
  dataIndex: string;
  render?: (_: any, record: any) => React.ReactNode;
};

const AcordoDetails = ({ acordo, getAcordo, salvarAcordo }: AcordoDetailProps) => {
  const {
    id,
    parcelas,
    formaPagamento,
    error,
    message,
    qtdParcelas,
    telefone,
    email,
    status,
    situacao
  } = acordo;

  const { dataModal } = useModalContext();

  async function forcarProcessamento(idPagamento: string) {
    Api()
      .pagamento.forcarProcessamento(idPagamento)
      .then((response) => getAcordo(id));
  }

  if (error) {
    return <div>{message}</div>;
  }

  const columns: ColumnType[] = [
    {
      title: '#',
      dataIndex: 'numeroParcela',
      render: (_, record) => {
        return <p style={{ textAlign: 'center', margin: 0 }}>{record.numeroParcela}</p>;
      }
    },
    {
      title: 'Vencimento',
      dataIndex: 'dataVencimento',
      render: (_, record) => {
        let color = '#000';

        if (moment(record.dataVencimento).isBefore(new Date())) {
          color = 'red';
        } else {
          color = 'green';
        }

        return (
          <p style={{ textAlign: 'center', color, margin: 0 }}>
            {new Date(record.dataVencimento).toLocaleDateString('pt-Br', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric'
            })}
          </p>
        );
      }
    },
    {
      title: 'Data do pagamento',
      dataIndex: 'dataPagamento',
      render: (_, record) => {
        return (
          <p style={{ textAlign: 'center', margin: 0 }}>
            {record?.cobranca[0]?.pagamento?.data_pagamento
              ? new Date(record.cobranca[0].pagamento.data_pagamento).toLocaleDateString('pt-Br', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric'
                })
              : '-'}
          </p>
        );
      }
    },
    {
      title: 'Valor',
      dataIndex: 'valor',
      render: (_, record) => {
        return (
          <p style={{ textAlign: 'center', margin: 0 }}>
            {new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
              minimumFractionDigits: 2
            }).format(record.valor)}
          </p>
        );
      }
    },

    {
      title: 'Vencimento Cobrança',
      dataIndex: 'vencimentoCobranca',
      render: (_, record) => {
        let color = '#000';
        if (record.vencida) {
          color = 'red';
        }
        if (record.paga) {
          color = 'green';
        }
        const data = record?.cobranca[0]?.data_vencimento
          ? moment(record?.cobranca[0]?.data_vencimento).format(dateFormat)
          : '-';
        return <p style={{ textAlign: 'center', color, margin: 0 }}>{data}</p>;
      }
    },

    {
      title: 'Valor Cobrança',
      dataIndex: 'valorCobranca',
      render: (_, record) => {
        return (
          <p style={{ textAlign: 'center', margin: 0 }}>
            {record?.cobranca[0]?.valor
              ? new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 2
                }).format(record?.cobranca[0]?.parcela_cobranca?.valor_parcela)
              : '-'}
          </p>
        );
      }
    },
    {
      title: 'Método Pagamento',
      dataIndex: 'info_boleto',
      render: (_, record) => {
        return (
          <p style={{ textAlign: 'center', margin: 0 }}>
            {record?.cobranca[0]?.pagamento?.carteira_metodo_pagamento?.metodo_pagamento?.nome || '-'}
          </p>
        );
      }
    },

    {
      title: 'Informações de pagamento',
      dataIndex: 'info_boleto',
      render: (_, record) => {
          if(record?.cobranca[0]?.pagamento)
            return <RenderBoletoActionButtons data={record?.cobranca[0]?.pagamento} onForceProcess={forcarProcessamento} />
           return  <p style={{ textAlign: 'center', margin: 0 }}>{'-'}</p>;
          }
        },
    {
      title: 'Integração',
      dataIndex: 'idIntegracao',
      render: (_, record) => {
        const boletoJson =
          record?.cobranca[0]?.pagamento?.dados_metodo_pagamento &&
          record.cobranca[0].pagamento.dados_metodo_pagamento != null
            ? record?.cobranca[0]?.pagamento?.dados_metodo_pagamento
            : null;
        return <p style={{ textAlign: 'center', margin: 0 }}>{boletoJson?.numero || '-'}</p>;
      }
    },
    {
      title: 'Situação Pagamento',
      dataIndex: 'situacaoPagamento',
      render: (_, record) => {
        return <p style={{ textAlign: 'center', margin: 0 }}>{record?.cobranca[0]?.pagamento?.situacao || '-'}</p>;
      }
    },
    {
      title: 'Situação Parcela',
      dataIndex: 'situacao Parcela',
      render: (_, record) => {
        if(record?.situacao !== 'LIQUIDADO' && record?.cobranca[0]?.pagamento?.situacao == 'LIQUIDADO'){
          return <p style={{ textAlign: 'center', margin: 0 }}>
            {'PROCESSANDO PAGAMENTO'}
            </p>
        }
        return  <p style={{ textAlign: 'center', margin: 0 }}>
        {record?.situacao || '-'}
        </p>           
      }
    },
    {
      title: '',
      dataIndex: '',
      render: (parcela, record) => {
        return (
          <div>
            <Space>
              <Dropdown.Button
                trigger={['click']}
                overlay={
                  <ActionMenu
                    dataParcela={{
                      ...parcela,
                      statusAcordo: status,
                      situacaoAcordo: situacao
                    }}
                    getData={getAcordo}
                    getDataParams={[id]}
                  />
                }
              />
            </Space>
          </div>
        );
      }
    }
  ];

  const FormaDePagamento = ({ formaPagamento, parcelas, valor }: any) => {
    let label = '';
    switch (formaPagamento) {
      case 'parc':
        label = `Parcelado em ${parcelas} x de ${valor}`;
        break;
      case 'entParc':
        label = `Entrada + ${parcelas} parcelas de ${valor}`;
        break;
      case 'avista':
        label = 'À vista';
        break;
      default:
        break;
    }

    return <span>{label}</span>;
  };

  const handleChangeSituacao = async (situacao: number) => {
    Api().agreements.updateSituacao(id, situacao);
  };

  function handleChangeSerasa(status: number) {
    client.updateStatusSerasa(status, id);
  }

  const ContractList: React.FC<{items:any[]} > = (props) => {
    return (
        <ul>
          {props.items.map(item => (
              <li key={uuid()}>
                <a href={`${process.env.PUBLIC_URL}/contrato/${item.id}`}>{item.contrato}</a>
              </li>
          ))}
        </ul>
    );
  }

  return (
    <S.Wrapper>
      {dataModal?.visible && <Modal {...dataModal}>{dataModal.children}</Modal>}
      <h2>{`Acordo: ${id}`}</h2>
      <h4>
        Firmado em:{' '}
        {new Date(acordo.dataCriacao).toLocaleDateString('pt-br', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        })}
      </h4>
      <h4>
        Contratos contemplados:
        <ContractList items={acordo.acordo_contrato} />
      </h4>

      {acordo?.sdr && (<h4>SDR: {acordo.sdr.name}</h4>)}
      {acordo?.closer && (<h4>Closer: {acordo.closer.name}</h4>)}
      
      <div className="no-print">
        <Divider />
      </div>
      <Descriptions column={1}>
        <Descriptions.Item label="Tipo legal" className="legal-type">
          {String(acordo.tipoLegal).replace('_', '-')}
        </Descriptions.Item>
        <Descriptions.Item label="Valor acordado">
          <h3>
            <b>R$ {acordo.valor}</b>
          </h3>
        </Descriptions.Item>
        <Descriptions.Item label="Forma de pagamento">
          <FormaDePagamento
            formaPagamento={formaPagamento}
            parcelas={qtdParcelas}
            valor={parcelas && parcelas[parcelas.length - 1]?.valor}
          />
        </Descriptions.Item>
      </Descriptions>

      <Descriptions column={2}>
        <Descriptions.Item label="Valor sinal">R$ {acordo.sinal}</Descriptions.Item>
        <Descriptions.Item label="Valor entrada">R$ {acordo.entrada}</Descriptions.Item>
      </Descriptions>

      <Descriptions column={2}>
        <Descriptions.Item label="Percentual de desconto">{acordo.percDesconto}%</Descriptions.Item>
        <Descriptions.Item label="Desconto">R$ {acordo.desconto}</Descriptions.Item>
      </Descriptions>
      <Descriptions column={2}>
        <Descriptions.Item label="Data aceite">
          {acordo.dataAceite != null
            ? new Date(acordo.dataAceite).toLocaleDateString('pt-br', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
              })
            : ''}
        </Descriptions.Item>
        <Descriptions.Item label="Status">
          <StatusAcordo status={acordo.status} />
        </Descriptions.Item>
      </Descriptions>
      <Descriptions column={2}>
        <Descriptions.Item style={{ display: 'flex', flexDirection: 'column' }}>
          <p style={{ marginRight: '5px' }}>Situação: </p>
          <Select
            size="small"
            defaultValue={situacao}
            onChange={handleChangeSituacao}
            style={{ width: 120 }}>
            <Option value={0}>Regular</Option>
            <Option value={1}>Quebrado</Option>
            <Option value={2}>Liquidado</Option>
            <Option value={3}>Atrasado</Option>
          </Select>
        </Descriptions.Item>
        <Descriptions.Item label="Ativo">
          {acordo.active == 'ATIVO' ? 'Sim' : 'Não'}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions column={12}>
        <Descriptions.Item style={{ display: 'flex', flexDirection: 'column' }}>
          <p style={{ marginRight: '5px' }}>SERASA: </p>
          <Select
            size="small"
            defaultValue={0}
            onChange={handleChangeSerasa}
            style={{ width: 160 }}>
            <Option value={0}>Regular</Option>
            <Option value={1}>Enviar</Option>
            <Option value={2}>Remover</Option>
            <Option value={3}>Enviado</Option>
            <Option value={4}>Removido</Option>
            <Option value={5}>Fila de envio</Option>
            <Option value={6}>Fila de remoção</Option>
          </Select>
        </Descriptions.Item>
      </Descriptions>

      {status !== 3 && (
        <Row>
          <Col span={24} style={{ textAlign: 'left' }}>
            <Popconfirm
              title="Tem certeza que deseja aceitar esse acordo?"
              onConfirm={() => salvarAcordo(id)}>
              <Button type="primary">Aceitar acordo</Button>
            </Popconfirm>
          </Col>
        </Row>
      )}

      <Divider />
      {email && (
        <>
          <Descriptions title="Dados do devedor" column={1}>
            <Descriptions.Item label="Email">{email}</Descriptions.Item>
            <Descriptions.Item label="Telefone">{telefone}</Descriptions.Item>
          </Descriptions>
          <Divider />
        </>
      )}

      <div className="pagebreak" />

      <h3 className="mt20-print">Parcelas: </h3>
      <Table
        scroll={{ x: true }}
        columns={columns}
        dataSource={parcelas}
        pagination={false}
        bordered
        size="small"
      />

      <Divider />

      <Row className="no-print">
        <Col span={24} style={{ textAlign: 'center' }}>
          <>
            <Button
              type={'secondary' as any}
              onClick={() => {
                document.title = `Acordo`;
                window.print();
              }}>
              Imprimir acordo
            </Button>
          </>
        </Col>
      </Row>
    </S.Wrapper>
  );
};

export default AcordoDetails;
