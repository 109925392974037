import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { useAuth } from 'context/AuthContext';
import 'moment/locale/pt-br';
import { v4 as uuid } from 'uuid';

import { DeleteOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Collapse,
  Divider,
  Form,
  Modal,
  Popconfirm,
  Select,
  Table,
  message
} from 'antd';

const { Option } = Select;

import moment from 'moment';
import { Api } from '../client/lib/api';
import ContractDetails from '../components/ContractDetails';
import StatusAcordo from '../components/StatusAcordo';

import { HANDLER_ERRORS } from 'errors';
import { SUCCESS_MESSAGES } from 'helpers';
import { useNavigate } from 'react-router-dom';

const { Panel } = Collapse;

import SimuladorPersonalizado from '../components/SimuladorPersonalizado';

function disabledDate(current) {
  return false; //current && current.valueOf() <= moment().subtract(1, 'd').valueOf();
}

const Contrato = ({ data, generateAcordo, changeAcordoStatus }) => {
  const { userInfo } = useAuth();
  const [form] = Form.useForm();
  const [valorAcordado, setValorAcordado] = useState(data.valorAtualizado);
  const [contrato, setContrato] = useState(data.contrato);
  const [contractList, setContractList] = useState([]);
  const [selectedContractList, setSelectedContractList] = useState([]);
  const [parcelas, setParcelas] = useState(1);
  const [telephone, setTelephone] = useState(
    data.contrato.devedor?.telefoneResidencial
      ? data.contrato.devedor?.dddResidencial + data.contrato.devedor?.telefoneResidencial
      : ''
  );
  const [showForm, setShowForm] = useState(true);
  const [loading, setLoading] = useState(false);
  const [paymentType, setPaymentType] = useState('parc');
  const [showSinal, setShowSinal] = useState(true);
  const [showEntrada, setShowEntrada] = useState(true);
  const [hasSinal, setHasSinal] = useState(false);
  const [hasEntrada, setHasEntrada] = useState(false);
  const [selectedLegalType, setSelectedLegalType] = useState('extra_judicial');
  const [legalTypes, setLegalTypes] = useState([]);
  const dateFormat = 'DD/MM/YYYY';
  const [totalSelectedValues, setTotalSelectedValues] = useState(0);
  const [parcelaProximoMes, setParcelaProximoMes] = useState(true);
  const [selectedRadio, setSelectedRadio] = useState('personalizado');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();

  function calcDesconto(valor) {
    return parseFloat(Number(data.valorAtualizado) - valor).toFixed(2);
  }

  function calcValueByPercentual(value) {
    const valorFinalAtualizado = data.valorAtualizado;
    return ((value / valorFinalAtualizado - 1) * 100 * -1).toFixed(2);
  }

  function calcPercentAndValue(valor) {
    form.setFieldsValue({ descontoPerc: calcValueByPercentual(valor) });
    form.setFieldsValue({ descontoValor: calcDesconto(valor) });
  }

  function calcValorFinalAndValorDesconto(descontoPerc) {
    const valorFinalAtualizado = data.valorAtualizado;

    if (descontoPerc === 0 || descontoPerc === '') {
      form.setFieldsValue({ valorFinal: valorFinalAtualizado });
      form.setFieldsValue({ descontoValor: 0 });
      return false;
    }

    const valor = parseFloat(
      valorFinalAtualizado - (valorFinalAtualizado * descontoPerc) / 100
    ).toFixed(2);
    const descontoValor = parseFloat(valorFinalAtualizado - valor).toFixed(2);
    form.setFieldsValue({ valorFinal: valor });
    form.setFieldsValue({ descontoValor: descontoValor });

    setValorAcordado(valor);
  }

  function calcValorFinalAndValorPercent(descontoValor) {
    const valorFinalAtualizado = data.valorAtualizado;
    const valor = parseFloat(valorFinalAtualizado - descontoValor).toFixed(2);

    form.setFieldsValue({ descontoPerc: calcValueByPercentual(valor) });
    form.setFieldsValue({ valorFinal: valor });
  }

  function onChangePaymentType(value) {
    if (value === 'avista') {
      setShowSinal(false);
      setShowEntrada(false);
      setParcelas(1);
      form.setFieldsValue({ parcelas: 1 });
    } else {
      setShowSinal(true);
      setShowEntrada(true);
    }
    setPaymentType(value);
  }

  function onValuesChange(obj) {
    const key = Object.keys(obj)[0];
    const value = Object.values(obj)[0];

    switch (key) {
      case 'valorFinal':
        calcPercentAndValue(value);
        break;
      case 'descontoPerc':
        calcValorFinalAndValorDesconto(value);
        break;
      case 'descontoValor':
        calcValorFinalAndValorPercent(value);
        break;

      default:
        break;
    }
  }

  const handleCreateAgreement = async (values) => {
    if (selectedContractList.length === 0) {
      message.error('Selecione ao menos um contrato');
      return false;
    }

    const body = {
      tipoLegal: values.tipoLegal ? values.tipoLegal : 'extra_judicial',
      contrato: contrato.contrato,
      contratoList: selectedContractList,
      contrato_id: contrato.id,
      cpfCnpj: contrato.devedor?.cpfCnpj,
      dataCriacao: new Date().toISOString(),
      desconto: Number(values.descontoValor),
      email: contrato.devedor?.email.toLowerCase(),
      novoEmail: values.novoEmail,
      entrada: values.valorEntrada ? values.valorEntrada : 0,
      formaPagamento: values.paymentTypeOpts ? values.paymentTypeOpts : 'parc',
      motivoRejeicao: 'N/A',
      observacao: 'N/A',
      percDesconto: Number(values.descontoPerc),
      qtdParcelas: values.parcelas ? values.parcelas : 1,
      sinal: values.valorSinal ? values.valorSinal : 0,
      situacao: 0,
      status: 1,
      statusSerasa: 0,
      telefone: values.novoTelefone
        ? values.novoTelefone
        : contrato.devedor?.telefoneResidencial
        ? contrato.devedor.dddResidencial + ' ' + contrato.devedor.telefoneResidencial
        : '',
      valorFinal: Number(values.valorFinal),
      vencimento: moment(values.pVencimento).format('YYYY-MM-DD'),
      vencimentoParcelas: values.vencimentoParcelas,
      parcelaProximoMes: parcelaProximoMes,
      vencimentoEntrada: moment(values.pVencimentoEntrada).format('YYYY-MM-DD'),
      vencimentoSinal: moment(values.pVencimentoSinal).format('YYYY-MM-DD')
    };

    Api()
      .agreements.generateAgreement(body)
      .then((response) => {
        message.success(SUCCESS_MESSAGES.AGREEMENTS.NEW_AGREEMENT_SUCCESS);
        navigate(`/acordo/${response.id}`);
      })
      .catch((error) => {
        console.error(error);
        message.error(HANDLER_ERRORS.AGREEMENTS.GENERATE_AGREEMENT_ERROR);
      });
  };

  const handleChangeEmailsCadastrados = async (e) => {
    form.setFieldsValue({ novoEmail: e });
  };

  const handleChangeTelefonesCadastrados = async (e) => {
    form.setFieldsValue({ novoTelefone: e });
    setTelephone(e);
  };

  const initialValues = {
    parcelas: 1,
    valorFinal: 0,
    contrato_id: contrato.id,
    contrato: contrato.contrato,
    paymentTypeOpts: 'parc',
    valorSinal: 0,
    vencimentoEntrada: 0,
    descontoPerc: 0,
    descontoValor: 0,
    valorEntrada: 0,
    novoEmail: contrato.devedor?.email.toLowerCase(),
    novoTelefone: contrato.devedor?.telefoneResidencial
      ? contrato.devedor.dddResidencial + ' ' + contrato.devedor?.telefoneResidencial
      : '',
    cpfCnpj: contrato.devedor?.cpfCnpj
  };

  const handleDelete = async (idAcordo) => {
    await changeAcordoStatus(idAcordo, 6).then(() => {
      window.location.reload();
    });
  };

  const handleSelectAllContracts = (e) => {
    if (e.target.checked) {
      const contracts = contractList.map((contract) => contract.contrato_id);
      setSelectedContractList(contracts);
    } else {
      setSelectedContractList([]);
    }
  };

  const columnsTblAcordos = [
    {
      title: '',
      dataIndex: 'operation',
      render: (_, record) => (
        <>
          {record.status == 1 && (
            <Popconfirm
              title="Tem certeza que você quer excluir?"
              onConfirm={() => handleDelete(record.id)}>
              <Button icon={<DeleteOutlined />} />
            </Popconfirm>
          )}
        </>
      )
    },
    {
      title: 'Ativo',
      dataIndex: 'acitve',
      render: (_, record) => {
        return record.active ? 'Sim' : 'Não';
      }
    },
    {
      title: '',
      dataIndex: 'id',
      render: (_, record) => {
        return <a href={`/acordo/${record.id}`}>{record.id}</a>;
      }
    },
    {
      title: 'Data criação',
      dataIndex: 'dataCriacao',
      render: (_, record) => {
        return <p>{moment(record.dataCriacao).format(dateFormat)}</p>;
      }
    },
    {
      title: 'Parcelas',
      dataIndex: 'qtdParcelas'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (_, record) => {
        return <StatusAcordo status={Number(record.status)} />;
      }
    },
    {
      title: 'Valor',
      dataIndex: 'status',
      render: (_, record) => {
        return (
          <p>
            {new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
              minimumFractionDigits: 2
            }).format(record.valor)}
          </p>
        );
      }
    }
  ];

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      Api()
        .agreements.getLegalTypes()
        .then((response) => setLegalTypes(response));
    }
    return () => {
      isSubscribed = false;
    };
  }, []);

  useEffect(() => {
    Api()
      .contracts.getContractsByDevedorIdAndCarteiraId(contrato.devedor_id, contrato.carteira_id)
      .then((response) => setContractList(response));
  }, []);

  const handleContractClick = (contract, addContract = false) => {
    if (addContract) {
      setSelectedContractList([...selectedContractList, contract.contrato_id]);
    } else {
      setSelectedContractList(selectedContractList.filter((item) => item !== contract.contrato_id));
    }
  };

  useEffect(() => {
    let total = contractList.reduce((accum, curr) => {
      if (selectedContractList.includes(curr.contrato_id)) {
        return accum + curr.saldoAPagar;
      }
      return accum;
    }, 0);

    total = Math.round(total * 100) / 100;
    setTotalSelectedValues(total);
  }, [selectedContractList]);

  const renderContractsCheckbox = (contractList) => {
    return contractList.map((contract) => (
      <>
        <Checkbox
          key={uuid()}
          value={contract.contrato}
          checked={selectedContractList.includes(contract.contrato_id)}
          onChange={(e) => {
            let addContract = false;
            if (e.target.checked) {
              addContract = true;
            }
            handleContractClick(contract, addContract);
          }}>
          {contract.contrato} <span style={{ color: 'red' }}>({`R$${contract.saldoAPagar}`})</span>{' '}
          - {contract.diasEmAtraso} dias em atraso
        </Checkbox>{' '}
        <br />
      </>
    ));
  };

  const diasMes = Array.from({ length: 31 }, (_, i) => ({ value: i + 1, label: i + 1 }));

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const fecharModal = () => {
    setIsModalOpen(false);
  };

  const onChange = (e) => {
    const radioValue = e.target.value;
    setSelectedRadio(radioValue);
  };

  return (
    <>
      <ContractDetails
        contrato={contrato.contrato}
        vencimentoDoContrato={contrato.vencimentoContratacao}
        saldoAPagar={contrato.saldoAPagar}
        valorContratado={contrato.valorContratado}
        valorAtualizado={data.valorAtualizado}
        dataAcordo={data.data_summary}
        taxa={contrato.taxaFinanceira}
        diasEmAtraso={data.diasEmAtraso}
        parcelasPagas={contrato.parcelasPagas}
        totalAmortizado={data.totalAmortizado}
        totalPendente={data.totalPendente}
        totalParcelas={contrato.totalParcelas}
        valorParcela={contrato.valorParcela}
        parcelasNaoPagas={contrato.parcelasNaoPagas}
        devedor={contrato.devedor}
        valorContrato={contrato.valorContrato}
        dataContratacao={contrato.dataContratacao}
      />

      <Divider />

      <Collapse>
        <Panel
          className="lista-acordos-contrato"
          header={`Acordos realizados (${contrato.acordos?.length})`}
          key="1">
          <Table
            columns={columnsTblAcordos}
            dataSource={contrato.acordos}
            pagination={false}
            size="small"
          />
        </Panel>
      </Collapse>

      <Divider />

      <Button type="primary" onClick={showModal}>
        Simular Acordo
      </Button>
      <Modal visible={isModalOpen} footer={null} onCancel={handleCancel} width={1000} height="auto">
        {showForm && (
          <div className="form-wrapper no-print">
            <a id="simulador" />
            <h2>Simular acordo</h2>
            {/* <Divider />
            <Radio.Group onChange={onChange} value={selectedRadio}>
              <Radio value="predefinido">Predefinido</Radio>
              <Radio value="personalizado">Personalizado</Radio>
            </Radio.Group> */}
            <Divider />

            <SimuladorPersonalizado dataContrato={data.contrato} fecharModal={fecharModal} />
          </div>
        )}
      </Modal>
    </>
  );
};

Contrato.propTypes = {
  data: PropTypes.object
};

export default Contrato;
