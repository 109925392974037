import BaseHttpClient from '../http/AxiosHttpClient';
import { CarteiraMetodoPagamento } from './models';

export type CreatePagamento = {
  id: string;
  carteira_metodo_id: string;
  identificador: string;
  desc_identificador: string;
  data_pagamento: string;
  valor_pago: number;
  parameters: any;
};
export type LiquidarPagamentoType = { data_pagamento: string; valor_pago: string };

export class PagamentoApi {
  private readonly _httpClient: BaseHttpClient;

  public constructor() {
    this._httpClient = new BaseHttpClient();
  }

  public async baixarPagamento(id: string) {
    return this._httpClient.post(`/api/1.0/pagamento/changetobaixado/${id}`, null);
  }

  public async liquidarPagamento(id: string, body: LiquidarPagamentoType) {
    return this._httpClient.post(`/api/1.0/pagamento/changetoliquidado/${id}`, body);
  }

  public async createPagamento(body: CreatePagamento) {
    return this._httpClient.post(`/api/1.0/pagamento/create`, body);
  }

  public async cancelPagamento(id: string, data: { force: boolean }) {
    return this._httpClient.post(`/api/1.0/pagamento/cancel/${id}`, data);
  }
 
  public async reversePagamento(id: string, data: { force: boolean }) {
    return this._httpClient.post(`/api/1.0/pagamento/reserse/${id}`, data);
  }

  public async forcarProcessamento(id: string) {
    return this._httpClient.post(`/api/1.0/pagamento/sync/${id}`,null);
  }


  public async getCarteiraMetodoPagamento(id: string): Promise<CarteiraMetodoPagamento[]> {
    return this._httpClient.get(`/api/1.0/pagamento/metodopagamento/${id}`);
  }
} 
