import React, { useState, useEffect } from 'react';
import { Table, Space, Form, Input, Button } from 'antd';

import { SearchOutlined } from '@ant-design/icons';

import { formatCpfCnpj } from '../../utils/cpf_cnpj';

function ContractList({ data, onChange }) {
  const { contratos, page, total } = data;

  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerms, setSearchTerms] = useState();

  const [form] = Form.useForm();

  const initPagination = {
    total: total,
    showTotal: (total, range) => `Total: ${total}`
  };

  useEffect(() => {
    setLoading(false);
  }, [data]);

  const handleTableChange = async (pagination, filters, sorter) => {
    setLoading(true);

    let current = pagination.current;

    if (searchTerms) {
      current = 1;
    } else {
      setSearchTerms(null);
    }

    await onChange(current, pagination.pageSize, searchTerms ? searchTerms : null);
    setLoading(false);
  };

  const onFinishSearch = async (searchTerms) => {
    setLoading(true);
    setSearchTerms(searchTerms);

    await onChange(1, pageSize, searchTerms);
    setLoading(false);
  };

  const columns = [
    {
      title: 'contrato',
      dataIndex: 'contrato',
      key: 'id',
      render: (text, record) => (
        <Space size="middle">
          <a href={`${process.env.PUBLIC_URL}/contrato/${record.id}`}>{record.contrato}</a>
        </Space>
      )
    },
    {
      title: 'valor contratado',
      dataIndex: 'valorContratado',
      key: 'valorContratado'
    },
    {
      title: 'devedor',
      key: 'devedor',
      dataIndex: 'devedor',
      render: (_, record) => {
        return (
          <div className="devedor-name">
            <p>
              <b>{record.devedor?.nome}</b>
              <br />
              {record.devedor?.email}
            </p>
          </div>
        );
      }
    },
    {
      title: 'telefone',
      key: 'telefones',
      dataIndex: 'telefones',
      render: (_, record) => {
        return <div className="productName">{record.devedor?.telefoneResidencial}</div>;
      }
    },
    {
      title: 'cpf',
      key: 'cpf',
      dataIndex: 'cpf',
      render: (_, record) => {
        return <div className="productName">{formatCpfCnpj(record.devedor?.cpfCnpj)}</div>;
      }
    }
    // {
    //   title: 'data contratação',
    //   dataIndex: 'dataContratacao',
    //   key: 'dataContratacao',
    // },
  ];

  return (
    <>
      <div style={{ height: '50px' }}>
        <Form form={form} name="horizontal_login" layout="inline" onFinish={onFinishSearch}>
          <Form.Item name="contrato">
            <Input placeholder="Contrato" />
          </Form.Item>

          <Form.Item name="cpfCnpj">
            <Input type="text" placeholder="CPF ou CNPJ" />
          </Form.Item>
          <Form.Item shouldUpdate>
            {() => (
              <Button icon={<SearchOutlined />} type="primary" htmlType="submit">
                Buscar
              </Button>
            )}
          </Form.Item>
        </Form>
      </div>

      <Table
        columns={columns}
        dataSource={contratos}
        pagination={initPagination}
        onChange={handleTableChange}
        loading={loading}
      />
    </>
  );
}

export default ContractList;
