import QueryString from 'qs';
import BaseHttpClient from '../http/AxiosHttpClient';

export class AgreementsApi {
  private readonly _httpClient: BaseHttpClient;

  public constructor() {
    this._httpClient = new BaseHttpClient();
  }

  public async generateAgreement(data: any) {
    return this._httpClient.post(`/api/1.0/acordo`, data);
  }

  public async getAgreements(params?: any): Promise<any> {
    if (params) {
      const paramsQs = QueryString.stringify(params);
      return this._httpClient.get(`/api/1.0/acordos?${paramsQs}`);
    }
    return this._httpClient.get(`/api/1.0/acordos`);
  }

  public async getAgreement(id: string): Promise<any> {
    return this._httpClient.get(`/api/1.0/acordo/${id}`);
  }

  public async getLegalTypes(): Promise<string[]> {
    return this._httpClient.get('api/1.0/acordos/tipos');
  }

  public async updateStatus(id : number, status: number) {
    return this._httpClient.put(`/api/1.0/acordo/status/`+id, { status: status} );
  }
  public async updateSituacao(id : number, situacao: number) {
    return this._httpClient.put(`/api/1.0/acordo/situacao/`+id, { situacao: situacao} );
  }
}
