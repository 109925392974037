import { message } from 'antd';
import axios, { AxiosInstance } from 'axios';
import qs from 'qs';

class AxiosHttpClient {
  protected readonly instance: AxiosInstance;

  public constructor(baseURL?: string, authenticated = true) {
    this.instance = axios.create({
      baseURL: process.env.REACT_APP_URL_API,
      withCredentials: true,
      paramsSerializer: (p) => qs.stringify(p)
    });
    axios.defaults.withCredentials = true;
    if (authenticated) {
      this.instance.interceptors.request.use(async (config) => {
        config.headers!.Authorization = `${process.env.REACT_APP_TOKEN_API}`;
        return config;
      });
      this.instance.interceptors.response.use(
        (response) => {
          return response;
        },
        async (error) => {
          const errorCodes = [401];

          if (error.response.data.message) {
            message.error(error.response.data.message);
          } else if (error.message) {
            message.error(error.message);
          }

          if (error && errorCodes.includes(error.response?.status)) {
            window.open('/system/login', '_self');
          }
          return Promise.reject(error);
        }
      );
    }
  }

  public async get<T>(url: string, query?: any, headers: any = {}): Promise<T> {
    const response = await this.instance?.get<T>(url, {
      params: query,
      headers
    });
    return response.data;
  }

  public async post<T>(url: string, body: unknown): Promise<T> {
    const response = await this.instance.post<T>(url, body);
    return response.data;
  }

  public async delete<T>(url: string): Promise<T> {
    const response = await this.instance.delete<T>(url);
    return response.data;
  }

  public async put<T>(url: string, body: unknown): Promise<T> {
    const response = await this.instance.put<T>(url, body);
    return response.data;
  }
}

export default AxiosHttpClient;
