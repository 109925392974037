export const HANDLER_ERRORS = {
  AGREEMENTS: {
    LIST_ERROR: 'Não foi possível listar os acordos no momento. Tente novamente mais tarde',
    SHOW_AGREEMENT_ERROR:
      'Não foi possível apresentar os dados do acordo no momento. Tente novamente mais tarde.',
    GENERATE_AGREEMENT_ERROR:
      'Não foi possível gerar acordo no momento. Tente novamente mais tarde.'
  },
  PARCELAS: {
    LIST_ERROR: 'Não foi possível lsitar as parcelas no momento. Tente novamente mais tarde.'
  }
};
