export const Status = ({ status }: any) => {
  let label = '';
  switch (String(status)) {
    case '1':
      label = 'Simulação';
      break;
    case '2':
      label = 'Enviado';
      break;
    case '3':
      label = 'Aceito';
      break;
    case '4':
      label = 'Quebrado';
      break;
    case '5':
      label = 'Liquidado';
      break;
    case '7':
      label = 'Atrasado';
      break;
    default:
      break;
  }
  return <b>{label}</b>;
};

export const Situacao = ({ situacao }: any) => {
  let label = '';
  switch (String(situacao)) {
    case '0':
      label = 'Regular';
      break;
    case '1':
      label = 'Quebrado';
      break;
    case '2':
      label = 'Liquidado';
      break;
    case '3':
      label = 'Atrasado';
      break;
    default:
      break;
  }

  return <b>{label}</b>;
};

export const filterOptions = [
  { label: 'Simulação', value: 1 },
  { label: 'Aceito', value: 3 }
];

export const filterSituacaoOptions = [
  { label: 'Regular', value: 0 },
  { label: 'Quebrado', value: 1 },
  { label: 'Liquidado', value: 2 },
  { label: 'Atrasado', value: 3 }
];
