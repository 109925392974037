import QueryString from 'qs';
import BaseHttpClient from '../http/AxiosHttpClient';

export type ContractsQueryParams = {
  current: number;
  pageSize: number;
  searchTerms: any;
};

export class ContractsApi {
  private readonly _httpClient: BaseHttpClient;
  private carteira = localStorage.getItem('carteira');

  public constructor() {
    this._httpClient = new BaseHttpClient();
  }

  public async getContracts(params?: ContractsQueryParams): Promise<any> {
    const defaultParams = {
      currentPage: 1,
      pageSize: 100,
      carteira: this.carteira
    };
    const _params = params ? params : defaultParams;

    const paramsQs = QueryString.stringify(_params);
    return this._httpClient.get(`/api/legacy/contratos/listar?${paramsQs}`);
  }

  public async getContractsByDevedorIdAndCarteiraId(devedor_id: string, carteira_id: string): Promise<any> {
    return this._httpClient.get(`/api/1.0/contrato/devedor/${devedor_id}/carteira/${carteira_id}`);
  }


  public async getContract(id: string): Promise<any> {
    return this._httpClient.get(`api/1.0/contrato/${id}`);
  }


}
