import styled from 'styled-components';

export const Wrapper = styled.div`
  .input-wrapper {
    margin-top: 20px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 220px);
    gap: 20px;
  }

  .label-type {
    text-transform: capitalize;
  }

  .grid-form {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 20px;
    align-items: center;
    margin-bottom: 20px;
  }
`;
