import { message } from 'antd';
import { Api } from 'client/lib/api';
import { useModalContext } from 'context/ModalContext';
import { forwardRef, useCallback, useImperativeHandle } from 'react';
import { IParcela } from '../models';

export type BaixarPagamentoHandler = {
  submitForm: () => void;
};

export type BaixarPagamentoProps = {
  data: any;
};

const BaixarPagamento: React.ForwardRefRenderFunction<
  BaixarPagamentoHandler,
  BaixarPagamentoProps
> = ({ data }, ref) => {
  const { hideModal, dataModal, onLoading, abortLoading } = useModalContext();

  const submitForm = useCallback(() => {
    if (data) {
      onLoading();
      Api()
        .pagamento.baixarPagamento(data.id)
        .then(() => {
          message.success('Pagamento baixado!');
          if (dataModal.onFinish) {
            dataModal?.onFinish();
          }
          hideModal();
        })
        .catch(() => abortLoading());
    }
  }, []);

  useImperativeHandle(ref, () => {
    return {
      submitForm
    };
  });

  return <p>Deseja baixar o pagamento desta parcela?</p>;
};

export default forwardRef(BaixarPagamento);
