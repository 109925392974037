import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Layout, Breadcrumb, Spin, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

import AcordoDetails from '../components/AcordoDetails';

import ContractClient from '../client/api';

import { Api } from '../client/lib/api';
import { HANDLER_ERRORS } from '../errors';

const { Content } = Layout;

function AcordoPage() {
  const { idacordo } = useParams();

  const [dataAcordo, setDataAcordo] = useState({});
  const [acordoResonse, setAcordoResonse] = useState({});

  const client = new ContractClient();


  const changeAcordoStatus = async (idAcordo, idStatus) => {
     Api()
      .agreements.updateStatus(idAcordo, idStatus)
      .then((response) => setDataAcordo(response));
  };

  async function getAcordo(idacordo) {
    await Api()
      .agreements.getAgreement(idacordo)
      .then((response) => setDataAcordo(response))
      .catch((error) => {
        console.error(error);
        message.error(HANDLER_ERRORS.AGREEMENTS.SHOW_AGREEMENT_ERROR);
      });
  }

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getAcordo(idacordo);
    }
    return () => {
      isSubscribed = false;
    };
  }, [idacordo, acordoResonse]);

  return (
    <Layout>
      <Breadcrumb className="no-print">
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Detalhe do acordo</Breadcrumb.Item>
      </Breadcrumb>
      <Content
        className="site-layout-background"
        style={{
          padding: 24,
          margin: 0,
          minHeight: 280,
          background: '#fff'
        }}>
        {Object.keys(dataAcordo).length > 0 ? (
          <AcordoDetails acordo={dataAcordo} getAcordo={getAcordo} salvarAcordo={changeAcordoStatus} />
        ) : (
          <div className="loadingWrapper">
            <Spin indicator={antIcon} />
            <p>Carregando...</p>
          </div>
        )}
      </Content>
    </Layout>
  );
}

export default AcordoPage;
