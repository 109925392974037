import { Button, message as antMessage } from 'antd';
import { Parcela } from 'interfaces';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import * as S from './styles';

type RenderBoletoActionButtonsProps = {
  onForceProcess: (payment_id: string) => void;
  data: Parcela;
};

export const RenderBoletoActionButtons = ({
  data,
  onForceProcess
}: RenderBoletoActionButtonsProps) => {
  function handleOpenBoleto(id: string, paymentMethod: string) {
    const urlMap = {
      boleto_bb: `${process.env.REACT_APP_URL_API}/api/1.0/boletos/bb/exibir/${id}`,
      caixa_legacy: `https://plugboleto.com.br/api/v1/boletos/impressao/${id}`
    };

    window.open(urlMap[paymentMethod as keyof typeof urlMap], '_blank');
  }

  function renderByPaymentMethod(data: any) {
    if (!data) return '-';

    const paymentId = data?.id;
    const paymentMethod =
    data?.carteira_metodo_pagamento?.metodo_pagamento.id_metodo_pagamento;
    const paymentData = data?.dados_metodo_pagamento;

    const paymentInProcess = data?.situacao == 'EM_PROCESSAMENTO';
    const emGeracao = data?.situacao == 'EM_GERACAO';


    if (paymentInProcess) {
      return (
        <Button onClick={() => onForceProcess(paymentId)}>Forçar Processamento do Pagamento</Button>
      );
    }

    if (emGeracao) {
      return (
        <Button onClick={() => onForceProcess(paymentId)}>Forçar Geração do Boleto</Button>
      );
    }

    switch (paymentMethod) {
      case 'boleto_bb':
        return (
          <>
            <Button onClick={() => handleOpenBoleto(paymentData.numero, paymentMethod)}>
              Ver boleto
            </Button>
            {paymentData && paymentData.linhaDigitavel && (
              <CopyToClipboard text={paymentData.linhaDigitavel}>
                <Button size={'small'} onClick={() => antMessage.success('Código copiado!')}>
                  Copiar código
                </Button>
              </CopyToClipboard>
            )}
          </>
        );
      case 'manual':
        return 'Pagamento manual';
      case 'caixa_legacy':
        return (
          <>
            <Button onClick={() => handleOpenBoleto(paymentData.numero, paymentMethod)}>
              Ver boleto
            </Button>
            {paymentData && paymentData.linhaDigitavel && (
              <CopyToClipboard text={paymentData.linhaDigitavel}>
                <Button size={'small'} onClick={() => antMessage.success('Código copiado!')}>
                  Copiar código
                </Button>
              </CopyToClipboard>
            )}
          </>
        );
      case 'another_agreement':
        return 'Pago em outro acordo';
      default:
        return '-';
    }
  }

  return <S.ActionBoletoButtonsWrapper>{renderByPaymentMethod(data)}</S.ActionBoletoButtonsWrapper>;
};
