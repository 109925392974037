import QueryString from 'qs';
import BaseHttpClient from '../http/AxiosHttpClient';

export type CarteirasQueryParams = {
  current: number;
  pageSize: number;
  searchTerms: any;
};

export class CarteirasApi {
  private readonly _httpClient: BaseHttpClient;

  public constructor() {
    this._httpClient = new BaseHttpClient();
  }

  public async getCarteiras(params?: CarteirasQueryParams): Promise<any> {
    const defaultParams = {
      currentPage: 1,
      pageSize: 100
    };
    const _params = params ? params : defaultParams;
    const paramsQs = QueryString.stringify(_params);
    return this._httpClient.get(`/api/1.0/carteiras/listar?${paramsQs}`);
  }
}
