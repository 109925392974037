import { ModalProps } from 'components/Modal';
import React, { createContext, useContext, useState } from 'react';

export interface DataModalType extends ModalProps {
  children: React.ReactNode;
  onFinish?: () => void;
}

export type ModalContextProps = {
  dataModal: DataModalType;
  setDataModal: (data: DataModalType) => void;
  hideModal: () => void;
  onLoading: () => void;
  abortLoading: () => void;
};

export type ModalContextProviderProps = {
  children: React.ReactNode;
};

export const ModalContext = createContext({} as ModalContextProps);

export const ModalContextProvider = ({ children }: ModalContextProviderProps) => {
  const [dataModal, setDataModal] = useState<DataModalType>({
    visible: false,
    children: null,
    confirmLoading: false
  });

  function hideModal() {
    return setDataModal({ visible: false, children: null, onFinish: () => {} });
  }

  function onLoading() {
    return setDataModal({ ...dataModal, confirmLoading: true });
  }

  function abortLoading() {
    return setDataModal({ ...dataModal, confirmLoading: false });
  }

  return (
    <ModalContext.Provider value={{ dataModal, setDataModal, hideModal, onLoading, abortLoading }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => {
  return useContext(ModalContext);
};
