import { AgreementsApi } from './agreements/AgreementsApi';
import { AuthApi } from './auth/AuthApi';
import { CarteirasApi } from './carteiras/CarteirasApi';
import { CobrancaApi } from './cobranca';
import { ContractsApi } from './contracts/ContractsApi';
import { PagamentoApi } from './pagamento/PagamentoApi';
import { ParcelaApi } from './parcelas/ParcelaApi';
import { UserApi } from './user/UserApi';


export const Api = () => ({
  auth: new AuthApi(),
  contracts: new ContractsApi(),
  carteiras: new CarteirasApi(),
  agreements: new AgreementsApi(),
  cobranca: new CobrancaApi(),
  pagamento: new PagamentoApi(),
  parcela: new ParcelaApi(),
  user: new UserApi(),
});
