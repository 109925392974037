import React from 'react';
import PropTypes from 'prop-types';

import { Divider, Descriptions } from 'antd';
import moment from 'moment';
const dateFormat = 'DD/MM/YYYY';
import { formatCpfCnpj } from '../../utils/cpf_cnpj';

function ContractDetails({
  contrato,
  vencimentoDoContrato,
  saldoAPagar,
  valorContratado,
  valorAtualizado,
  valorContrato,
  dataAcordo,
  dataContratacao,
  taxa,
  diasEmAtraso,
  parcelasPagas,
  totalParcelas,
  valorParcela,
  parcelasNaoPagas,
  devedor,
  totalAmortizado,
  totalPendente
}) {
  return (
    <>
      <Descriptions title={`Contrato: ${contrato}`} column={2}>
        <Descriptions.Item label="Data da contratação">{ moment(dataContratacao).format(dateFormat)}</Descriptions.Item>
        <Descriptions.Item label="Vencimento do contratação">
        { moment(vencimentoDoContrato).format(dateFormat)}
        </Descriptions.Item>
        <Descriptions.Item label="Saldo a pagar">{
         new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2
        }).format(saldoAPagar)
      }</Descriptions.Item>
        <Descriptions.Item label="Valor contratado">{
                  new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                    minimumFractionDigits: 2
                  }).format(valorContratado)
        }</Descriptions.Item>
        <Descriptions.Item label="Valor atualizado">
          {    
          new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2
          }).format(valorAtualizado)
          } em  { moment(dataAcordo).format(dateFormat)}
        </Descriptions.Item>
        <Descriptions.Item label="Taxa do contrato">
        {    
          new Intl.NumberFormat('pt-BR', {
            minimumFractionDigits: 2
          }).format(taxa)
          } 
        </Descriptions.Item>
        <Descriptions.Item label="Dias em atraso">
          {diasEmAtraso} (parcela vencida mais antiga)
        </Descriptions.Item>
      </Descriptions>
      <Descriptions column={2}>
        <Descriptions.Item label="Parcelas pagas">
          {parcelasPagas} de {totalParcelas}
        </Descriptions.Item>
        <Descriptions.Item label="Valor da parcela">{
        new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2
        }).format(valorParcela)
        }</Descriptions.Item>
        <Descriptions.Item label="Parcelas não pagas">{parcelasNaoPagas}</Descriptions.Item>
        <Descriptions.Item label="Valor de contrato">{
        new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2
        }).format(valorContrato)
        }</Descriptions.Item>
      </Descriptions>
      <Divider />
        <Descriptions title="Resolve Contas" size="small" column={1}>
          <Descriptions.Item label="Valor Amortizado">{
          new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2
          }).format(totalAmortizado)
         }</Descriptions.Item>
         <Descriptions.Item label="Valor Pendente">{
          new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2
          }).format(totalPendente)
         }</Descriptions.Item>
         <Descriptions.Item label="Valor Pendente Atualizado">{
          new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2
          }).format(valorAtualizado)
         }</Descriptions.Item>         
        </Descriptions>
      <Divider />
      <Descriptions title="Informações do cliente" size="small" column={3}>
        <Descriptions.Item label="CPF">{formatCpfCnpj(devedor?.cpfCnpj)}</Descriptions.Item>
      </Descriptions>
      <Descriptions column={2}>
        <Descriptions.Item label="Nome">{devedor?.nome}</Descriptions.Item>
        <Descriptions.Item label="Telefone residencial">
          {devedor?.telefoneResidencial?devedor?.dddResidencial + ' ' + devedor?.telefoneResidencial:''}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions column={2}>
        <Descriptions.Item label="Telefone 1 ">{devedor?.telefone1}</Descriptions.Item>
        <Descriptions.Item label="Telefone 2">{devedor?.telefone2}</Descriptions.Item>
        <Descriptions.Item label="Celular 1">{devedor?.celular1}</Descriptions.Item>
        <Descriptions.Item label="Celular 2">{devedor?.celular2}</Descriptions.Item>
      </Descriptions>
      <Divider />
      <Descriptions column={2}>
        <Descriptions.Item label="Email">{devedor?.email}</Descriptions.Item>
        <Descriptions.Item label="Email 2">{devedor?.email2}</Descriptions.Item>
        <Descriptions.Item label="Email 3">{devedor?.email3}</Descriptions.Item>
        <Descriptions.Item label="Email 4">{devedor?.email4}</Descriptions.Item>
        <Descriptions.Item label="Email Acordo Easy">{devedor?.email_acordoeasy}</Descriptions.Item>
      </Descriptions>
      <Divider />
      <Descriptions column={3}>
        <Descriptions.Item label="Logradouro">{devedor?.enderecoResidencia}</Descriptions.Item>
      </Descriptions>
      <Descriptions column={2}>
        <Descriptions.Item label="Número">{devedor?.numeroResidencia || '-'}</Descriptions.Item>
        <Descriptions.Item label="Complemento">{devedor?.complementoResidencia}</Descriptions.Item>
      </Descriptions>
      <Descriptions column={1}>
        <Descriptions.Item label="CEP">{devedor?.cepResidencia}</Descriptions.Item>
      </Descriptions>
      <Descriptions column={4}>
        <Descriptions.Item label="Bairro">{devedor?.bairroResidencia}</Descriptions.Item>
        <Descriptions.Item label="Cidade">{devedor?.cidadeResidencia}</Descriptions.Item>
        <Descriptions.Item label="Estado">{devedor?.ufResidencia}</Descriptions.Item>
        <Descriptions.Item label="País">{devedor?.pais?'':'Brasil'}</Descriptions.Item>
      </Descriptions>
    </>
  );
}

ContractDetails.propTypes = {
  contrato: PropTypes.string,
  vencimentoDoContrato: PropTypes.string,
  saldoAPagar: PropTypes.string,
  valorContratado: PropTypes.string,
  valorAtualizado: PropTypes.string,
  dataAcordo: PropTypes.string,
  taxa: PropTypes.number,
  diasEmAtraso: PropTypes.number,
  parcelasPagas: PropTypes.number,
  totalParcelas: PropTypes.number,
  valorParcela: PropTypes.string,
  parcelasNaoPagas: PropTypes.number,
  devedor: PropTypes.object
};

export default ContractDetails;
