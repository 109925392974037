import React, { useState, useEffect } from 'react';
import { Menu, Form, Select } from 'antd';
import { Api } from '../../client/lib/api';
const { Option } = Select;

import { Link, useNavigate } from 'react-router-dom';

import { menuMap } from './helpers/MenuPaths';
import { useAuth } from '../../context/AuthContext';

export type Carteira = {
  id: number;
  nome: string;
};

const MenuDashboard = () => {
  const [selectedKey, setSelectedKey] = useState('');
  const navigate = useNavigate();
  const { userPermissions } = useAuth();
  const [carteiras, setCarteiras] = useState<Carteira[]>([]);
  const [carteira, setCarteira] = useState('Todas as carteiras');

  useEffect(() => {
    let isSubscribed = true;
    Api()
      .carteiras.getCarteiras()
      .then((data) => {
        if (isSubscribed) {
          setCarteiraValue(data);
          setCarteiras(data);
        }
      });

    return () => {
      isSubscribed = false;
    };
  }, []);

  function setCarteiraValue(data: Carteira[]) {
    if (data?.length > 0) {
      if (!localStorage.getItem('carteira')) {
        localStorage.setItem('carteira', String(data[0].id));
        setCarteira(data[0].nome);
      } else {
        const foundCarteira = data.find(
          (element) => element.id === parseInt(localStorage.getItem('carteira')!)
        );
        if (foundCarteira) {
          setCarteira(foundCarteira.nome);
        }
      }
    } else {
      localStorage.setItem('carteira', '0');
    }
  }

  function handleSelectedSection(key: string) {
    setSelectedKey(key);
  }

  const selectCarteiraHandle = (value: number) => {
    if (carteiras && carteiras.length > 0) {
      const foundCarteira = carteiras.find((element) => element.id === value);
      if (foundCarteira) {
        setCarteira(foundCarteira.nome);
        localStorage.setItem('carteira', String(value));
        navigate('/contratos', { replace: true });
        setSelectedKey('contratos');
        location.reload();
      } else {
        localStorage.setItem('carteira', '0');
        navigate('/contratos');
        setSelectedKey('Todas as carteiras');
        location.reload();
      }
    } else {
      location.reload();
      setSelectedKey('');
    }
  };

  return (
    <Menu mode="inline" selectedKeys={[selectedKey]} style={{ height: '100%' }}>
      {carteiras && carteiras.length > 0 && (
        <div className="seletor-carteira">
          <Form.Item name="carteira">
            <Select
              size="large"
              onChange={(value) => selectCarteiraHandle(+value)}
              defaultValue={carteira}
              optionLabelProp="title">
              <Option title="Todas as carteiras" value={0}>
                Todas as carteiras
              </Option>
              {carteiras.map((element, index) => (
                <Option key={index} title={element.nome} value={element.id}>
                  {element.nome}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      )}
      {userPermissions.map((permission: string) =>
        menuMap[permission].external ? (
          <Menu.Item key={menuMap[permission].key} icon={menuMap[permission]?.icon}>
            <a href={menuMap[permission]?.path}>{menuMap[permission].label}</a>
          </Menu.Item>
        ) : (
          <Menu.Item
            key={menuMap[permission].key}
            icon={menuMap[permission]?.icon}
            onClick={() => handleSelectedSection(menuMap[permission].key)}>
            <Link to={menuMap[permission].path}>{menuMap[permission].label}</Link>
          </Menu.Item>
        )
      )}

      {/*<Menu.Item key="boletos" icon={<BarcodeOutlined />}><a href="/boletos">Boletos</a></Menu.Item>*/}
      {/* <Menu.Item key="3" icon={<TeamOutlined />}><a href="/devedores">Devedores</a></Menu.Item> */}
    </Menu>
  );
};

export default MenuDashboard;
