import React from 'react';
import { Route, Routes as Switch } from 'react-router-dom';

import ContratosPage from './pages/ContratosPage';
import ContratoPage from './pages/ContratoPage';
import AcordoPage from './pages/AcordoPage';
import AcordosPage from './pages/AcordosPage';
//import BoletosPage from './pages/BoletosPage';
import ParcelasPage from './pages/ParcelasPage';
import { ProtectedRoute } from './helpers/ProtectedRoute';
import { CONSTANTS } from './context/Auth/constants';

export default function Routes() {
  return (
    <Switch>
      <Route path="*" element={<h1>Page not found - 404</h1>} />
      <Route path="/" element={<h1>Página inicial</h1>} />
      <Route
        path="/contratos"
        element={
          <ProtectedRoute alias={CONSTANTS.PERMISSIONS.CONTRACTS}>
            <ContratosPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/contrato/:idcontrato"
        element={
          <ProtectedRoute alias={CONSTANTS.PERMISSIONS.CONTRACTS}>
            <ContratoPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/acordos"
        element={
          <ProtectedRoute alias={CONSTANTS.PERMISSIONS.AGREEMENTS}>
            <AcordosPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/acordo/:idacordo"
        element={
          <ProtectedRoute alias={CONSTANTS.PERMISSIONS.AGREEMENTS}>
            <AcordoPage />
          </ProtectedRoute>
        }
      />
      {/* <Route exact path="/boletos" element={BoletosPage} /> */}
      <Route
        path="/parcelas"
        element={
          <ProtectedRoute alias={CONSTANTS.PERMISSIONS.INSTALMENTS}>
            <ParcelasPage />
          </ProtectedRoute>
        }
      />
    </Switch>
  );
}
