import React, { createContext, useContext, useEffect, useState } from 'react';
import { Api } from '../client/lib/api';

export type AuthContextProps = {
  userLogged: boolean;
  userInfo: any;
  signOut: () => void;
  userPermissions: string[];
};

export const AuthContext = createContext({} as AuthContextProps);

export type AuthContextProviderProps = {
  children: React.ReactNode;
};

export const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const [userLogged, setUserLogged] = useState(true);
  const [userInfo, setUserInfo] = useState({});
  const [userPermissions, setUserPermissions] = useState<string[]>([
    'contratos',
    'acordos',
    'parcelas'
  ]);

  function signOut() {
    Api()
      .auth.logout()
      .then(() => setUserLogged(true));
    window.open('system/login', '_self');
  }

  useEffect(() => {
    Api()
      .auth.getCurrentUser()
      .then((data) => {
        setUserInfo(data);
        setUserLogged(true);
        Api()
          .auth.getMenuPermissions()
          .then((data) => setUserPermissions(data))
          .catch((err) => {
            console.error(err);
            setUserLogged(false);
            setUserPermissions([]);
          });
      })
      .catch((err) => {
        console.error(err);
        setUserLogged(false);
        setUserPermissions([]);
      });
  }, []);

  return (
    <AuthContext.Provider value={{ userLogged, signOut, userPermissions, userInfo }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
