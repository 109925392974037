import { DatePicker, Form, InputNumber, message } from 'antd';
import { forwardRef, useCallback, useImperativeHandle } from 'react';
import { IParcela } from '../models';
import locale from 'antd/es/date-picker/locale/pt_BR';
import { Api } from 'client/lib/api';
import moment from 'moment';
import { useModalContext } from 'context/ModalContext';
import { LiquidarPagamentoType } from 'client/lib/pagamento/PagamentoApi';

export type LiquidarPagamentoHandler = {
  submitForm: () => void;
};

export type LiquidarPagamentoProps = {
  data: any;
};

const LiquidarPagamento: React.ForwardRefRenderFunction<
  LiquidarPagamentoHandler,
  LiquidarPagamentoProps
> = ({ data }, ref) => {
  const [form] = Form.useForm();

  const { hideModal, dataModal, onLoading, abortLoading } = useModalContext();

  const submitForm = useCallback(() => {
    form.validateFields();
    const formValues = form.getFieldsValue();

    if (data) {
      onLoading();

      const body: LiquidarPagamentoType = {
        data_pagamento: moment(formValues.data_pagamento).format('DD/MM/yyyy'),
        valor_pago: formValues.valor_pago
      };
      Api()
        .pagamento.liquidarPagamento(data.id, body)
        .then(() => {
          message.success('Pagamento liquidado!');
          if (dataModal.onFinish) {
            dataModal?.onFinish();
          }
          hideModal();
        })
        .catch(() => abortLoading());
    }
  }, [form]);

  useImperativeHandle(ref, () => {
    return {
      submitForm
    };
  });

  const initialValues = {
    valor_pago: data?.valor_pago,
    data_pagamento: moment(new Date())
  };

  return (
    <Form form={form} initialValues={initialValues}>
      <Form.Item
        label="Valor pago"
        name="valor_pago"
        rules={[
          {
            required: true,
            message: 'Informe o valor a ser pago'
          }
        ]}>
        <InputNumber prefix={'R$'} style={{ width: '100%' }} decimalSeparator={','} size="large" />
      </Form.Item>
      <Form.Item
        name="data_pagamento"
        label="Data de pagamento"
        rules={[
          {
            required: true,
            message: 'Informe a data de pagamento'
          }
        ]}>
        <DatePicker
          size="large"
          format={'DD/MM/YYYY'}
          locale={locale}
          defaultValue={moment(new Date())}
        />
      </Form.Item>
    </Form>
  );
};

export default forwardRef(LiquidarPagamento);
