import ContractClient from './api';
class PlugBoletoClient {
  constructor() {
    this.urlApi = process.env.REACT_APP_URL_API_PLUGBOLETO;
    this.client = new ContractClient();
  }

  onError = (error) => {};

  async incluirBoleto(dataBoleto, idParcela) {
    const postObject = [
      {
        CedenteContaNumero: `${process.env.REACT_APP_CedenteContaNumero}`,
        CedenteContaNumeroDV: `${process.env.REACT_APP_CedenteContaNumeroDV}`,
        CedenteConvenioNumero: `${process.env.REACT_APP_CedenteConvenioNumero}`,
        CedenteContaCodigoBanco: `${process.env.REACT_APP_CedenteContaCodigoBanco}`,
        ...dataBoleto
      }
    ];

    let error = false;
    let message = '';
    let sucesso = '';

    const response = fetch(this.urlApi + '/boletos/lote', {
      method: 'POST',
      headers: {
        'cnpj-cedente': process.env.REACT_APP_CNPJ_CEDENTE,
        'cnpj-sh': process.env.REACT_APP_CNPJ_SOFTWAREHOUSE,
        'token-sh': process.env.REACT_APP_TOKEN_SOFTWAREHOUSE,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(postObject)
    })
      .then((res) => res.json())
      .then(({ _dados }) => {
        error = false;
        let falha = {};

        const { _falha } = _dados;

        let errors = (_falha[0] && _falha[0]._erro && _falha[0]._erro.erros) || '';
        if (_falha[0]) {
          error = true;
          falha = _falha[0];
        }

        errors = Object.values(errors).join(',');

        return { _dados, error, errors, falha };
      })
      .catch((err) => {
        message =
          'Erro ao tentar se comunicar com o servidor. Por favor, tente novamente ou entre em contato conosco.';
        error = true;
        return { message, error, sucesso };
      });
    return response;
  }

  async consultarBoleto(valor, idParcela) {
    var parametro = /[a-z]/i.test(valor) ? 'IdIntegracao' : 'TituloNossoNumero';

    const data = fetch(this.urlApi + '/boletos?' + parametro + '=' + valor, {
      method: 'GET',
      headers: {
        'cnpj-cedente': process.env.REACT_APP_CNPJ_CEDENTE,
        'cnpj-sh': process.env.REACT_APP_CNPJ_SOFTWAREHOUSE,
        'token-sh': process.env.REACT_APP_TOKEN_SOFTWAREHOUSE,
        'Content-Type': 'application/json'
      }
    })
      .then((res) => res.json())
      .then(async ({ _status, _dados }) => {
        const { IdIntegracao, situacao, TituloCodigoBarras, UrlBoleto, TituloNossoNumero } =
          _dados[0];

        if (situacao !== 'FALHA') {
          await this.client.updateParcela(
            idParcela,
            IdIntegracao,
            situacao,
            TituloCodigoBarras,
            UrlBoleto,
            TituloNossoNumero
          );
        } else {
          this.descartarBoleto(IdIntegracao, idParcela);

          return { _status, _dados, _erro: 'O boleto não foi registrado com sucesso.' };
        }

        return { _status, _dados };
      })
      .catch((err) => {
        return {
          error:
            'Erro ao tentar se comunicar com o servidor. Por favor, tente novamente ou entre em contato conosco.'
        };
      });
    return data;
  }

  async descartarBoleto(IdIntegracao, idParcela) {
    const postObject = [IdIntegracao];

    const response = fetch(this.urlApi + '/boletos/descarta/lote', {
      method: 'POST',
      headers: {
        'cnpj-cedente': process.env.REACT_APP_CNPJ_CEDENTE,
        'cnpj-sh': process.env.REACT_APP_CNPJ_SOFTWAREHOUSE,
        'token-sh': process.env.REACT_APP_TOKEN_SOFTWAREHOUSE,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(postObject)
    })
      .then((res) => res.json())
      .then(async (data) => {
        await this.client.updateParcela(idParcela, '', '', '', '', '');
        return data;
      })
      .catch((err) => {
        return err;
      });
    return response;
  }

  async listarBoletos(idIntegracao = '', status = []) {
    let filters = status.map((s) => 'situacao=' + s);

    if (idIntegracao !== '') {
      filters.push('idIntegracao=' + idIntegracao);
    }

    const data = fetch(this.urlApi + '/boletos?' + filters.join('&'), {
      method: 'GET',
      headers: {
        'cnpj-cedente': process.env.REACT_APP_CNPJ_CEDENTE,
        'cnpj-sh': process.env.REACT_APP_CNPJ_SOFTWAREHOUSE,
        'token-sh': process.env.REACT_APP_TOKEN_SOFTWAREHOUSE,
        'Content-Type': 'application/json'
      }
    })
      .then((res) => res.json())
      .then(async ({ _status, _dados, _meta }) => {
        return { _status, _dados, _meta };
      })
      .catch((err) => {
        return {
          error:
            'Erro ao tentar se comunicar com o servidor. Por favor, tente novamente ou entre em contato conosco.'
        };
      });
    return data;
  }

  async enviarRemessa(IdIntegracao) {
    const postObject = JSON.stringify(IdIntegracao);
    const response = fetch(this.urlApi + '/remessas/lote', {
      method: 'POST',
      headers: {
        'cnpj-cedente': process.env.REACT_APP_CNPJ_CEDENTE,
        'cnpj-sh': process.env.REACT_APP_CNPJ_SOFTWAREHOUSE,
        'token-sh': process.env.REACT_APP_TOKEN_SOFTWAREHOUSE,
        'Content-Type': 'application/json'
      },
      body: postObject
    })
      .then((res) => res.json())
      .then(async (data) => {
        const dadosRemessa = await this.client.salvarRemessa(data);
        return dadosRemessa;
      })
      .catch((err) => {
        return err;
      });
    return response;
  }
}
export default PlugBoletoClient;
