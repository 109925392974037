import { DatePicker, Form, Input, InputNumber, message, Radio, Select } from 'antd';
import locale from 'antd/es/date-picker/locale/pt_BR';
import TextArea from 'antd/lib/input/TextArea';
import { Api } from 'client/lib/api';
import { CreateCobranca as ICreateCobranca } from 'client/lib/cobranca/models';
import { useModalContext } from 'context/ModalContext';
import moment from 'moment';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { IParcela } from '../models';

export type CreateCobrancaHandler = {
  submitForm: () => void;
};

export type CreateCobrancaProps = {
  data: IParcela;
};

const CreateCobranca: React.ForwardRefRenderFunction<CreateCobrancaHandler, CreateCobrancaProps> = (
  { data },
  ref
) => {
  const [form] = Form.useForm();
  const [paymentType, setPaymentType] = useState('');
  const [textoParcela, setTextoParcela] = useState('');
  const { hideModal, dataModal, onLoading, abortLoading } = useModalContext();
  const { Option } = Select;
  const submitForm = useCallback(async () => {
    const formValues = form.getFieldsValue();
    onLoading();
    const selectedPaymentMethod = data?.carteiraMetodoPagamento?.find(
      (item) => item.metodo_pagamento.id_metodo_pagamento === paymentType
    );

    if (!selectedPaymentMethod) {
      return message.error('Não foi possível identificar método de pagamento');
    }
    const body: ICreateCobranca = {
      parcelas: [
        {
          id: data.id,
          valor: formValues.valor_pago
        }
      ],
      carteira_metodo_id: selectedPaymentMethod.id,
      data_vencimento: moment(formValues.data_vencimento).format('DD/MM/yyyy'),
      data_pagamento:
        (paymentType === 'manual' || paymentType === 'another_agreement') ? moment(formValues.data_pagamento).format('DD/MM/yyyy') : '',
      valor_pago: (paymentType === 'manual' || paymentType === 'another_agreement') ? formValues.valor_pago : null,
      desc_cobranca: formValues.desc_cobranca ?? textoParcela,
      identificador: (paymentType === 'manual' || paymentType === 'another_agreement') ? formValues.identificador : '',
      descricao_pagamento: (paymentType === 'manual' || paymentType === 'another_agreement') ? formValues.identificador : '',
      parameters: (paymentType === 'cc_assas') ? {
        'holderName' : formValues.holderName,
        'cardNumber' : formValues.cardNumber,
        'expiryMonth' : formValues.expiryMonth,
        'expiryYear' : formValues.expiryYear,
        'ccv' : formValues.ccv,
        'name' :  formValues.name,
        'email' :  formValues.email ,
        'cpfCnpj' : formValues.cpfCnpj ,
        'postalCode' : formValues.postalCode,
        'addressNumber' : formValues.addressNumber,
        'phone' : formValues.phone,
        'type' : formValues.type,
        'saveCard' : formValues.savecard && formValues.savecard=='true'?true:false,
        'token' : formValues.token 
      }
      :{}
    };

    Api()
      .cobranca.createCobranca(body)
      .then(() => {
        message.success('Cobrança criada com sucesso!');
        if (dataModal.onFinish) {
          dataModal?.onFinish();
        }
        hideModal();
      })
      .catch(() => abortLoading());
  }, [form, textoParcela, paymentType, data.carteiraMetodoPagamento]);

  useEffect(() => {
    Api()
      .cobranca.getParcelaText(data.id)
      .then((text) => setTextoParcela(text))
      .catch((err) => console.error(err)); 
  }, []);

  useImperativeHandle(ref, () => {
    return {
      submitForm
    };
  });

  const initialValues = {
    valor_pago: data.valor,
    data_vencimento: moment(new Date(data.dataVencimento)),
    holderName :'joao teste 5',
    cardNumber : '5162306219378829',
    expiryMonth : '5',
    expiryYear : '2024',
    ccv : '181',
    name :  'joao teste 5',
    email :  'marcelo.almeida@gmail.com' ,
    cpfCnpj : '24971563792' ,
    postalCode : '89223-005',
    addressNumber : '277',
    phone : '4738010919',
    type : 'card',
    saveCard : 'false',
    token : '1185a08e-a330-4cf3-a8de-93a8733e2a9f'
  };


  const onPaymentMethodChange = (value: string) => {
    const paymentMethods = data?.carteiraMetodoPagamento?.filter(function (el) {
      if (el.id == value)
      {
        return el;
      }
    })
    if (paymentMethods && paymentMethods.length > 0){
      const paymentType=paymentMethods[0].metodo_pagamento.id_metodo_pagamento;
      setPaymentType(paymentType);
    }
  };




  return (
    <Form form={form} initialValues={initialValues}>
      <Form.Item
        label="Opções de pagamento:"
        name="paymentTypeOpts"
        rules={[
          {
            required: true
          }
        ]}>
        <Select 
          onChange={onPaymentMethodChange} placeholder="Selecione o Método de Pagamento" >
          {data?.carteiraMetodoPagamento?.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.metodo_pagamento.nome}
            </Option>
          ))}
        </Select>
      </Form.Item>
      {paymentType && paymentType === 'manual' && (
        <>
          <Form.Item
            label="Identificador do Depósito"
            name="identificador"
            rules={[
              {
                required: true,
                message: 'Informe o identificador do depósito'
              }
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Descrição do pagamento"
            name="descricao_pagamento"
            rules={[
              {
                required: true,
                message: 'Informe a descrição do pagamento'
              }
            ]}>
            <TextArea rows={6} />
          </Form.Item>
          <Form.Item
            label="Data do pagamento"
            name="data_pagamento"
            rules={[
              {
                required: true,
                message: 'Informe a data do pagamento'
              }
            ]}>
            <DatePicker
              size="large"
              format={'DD/MM/YYYY'}
              locale={locale}
              defaultValue={moment(new Date())}
            />
          </Form.Item>
        </>
      )}
      {paymentType && paymentType === 'another_agreement' && (
        <>
          <Form.Item
            label="Número do acordo que ajusta essa parcela"
            name="identificador"
            rules={[
              {
                required: true,
                message: 'Informe o identificador do depósito'
              }
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Data do pagamento"
            name="data_pagamento"
            rules={[
              {
                required: true,
                message: 'Informe a data do pagamento'
              }
            ]}>
            <DatePicker
              size="large"
              format={'DD/MM/YYYY'}
              locale={locale}
              defaultValue={moment(new Date())}
            />
          </Form.Item>
        </>
      )}
      {paymentType && paymentType === 'cc_assas' && (
     <>
          <Form.Item
            label="Nome no Cartão de Crédito"
            name="holderName"
            >
             <Input />
          </Form.Item>
          <Form.Item
            label="Número no Cartão de Crédito"
            name="cardNumber"
            >
            <Input  />
          </Form.Item>
          <Form.Item
            label="Mês de expiração do Cartão"
            name="expiryMonth"
            >
             <Input  />
          </Form.Item>
          <Form.Item
            label="Ano de expiração do Cartão"
            name="expiryYear"
            >
            <Input  />
          </Form.Item>
          <Form.Item
            label="Código de Verificação do cartão"
            name="ccv"
            >
            <Input  />
          </Form.Item>
          <Form.Item
            label="Nome Completo"
            name="name"
            >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            >
            <Input />
          </Form.Item>
          <Form.Item
            label="CNPJ"
            name="cpfCnpj"
            >
            <Input   />
          </Form.Item>
          <Form.Item
            label="CEP"
            name="postalCode"
            >
            <Input value={'89223-005'}/>
          </Form.Item>
          <Form.Item
            label="Numero"
            name="addressNumber"
            >
            <Input />
          </Form.Item>
          <Form.Item
            label="Telefone"
            name="phone"
            >
            <Input  />
          </Form.Item>
          <Form.Item
            label="Type[token:card]"
            name="type"
            >
            <Input   />
          </Form.Item>
          <Form.Item
            label="SaveCard[true:false]"
            name="savecard"
            >
            <Input  />
          </Form.Item>
          <Form.Item
            label="Token"
            name="token"
            >
            <Input />
          </Form.Item>
        </>
      )}
      {paymentType && (
        <>
          <Form.Item
            label="Valor à pagar"
            name="valor_pago"
            rules={[
              {
                required: true,
                message: 'Informe o valor a ser pago'
              }
            ]}>
            <InputNumber
              prefix={'R$'}
              style={{ width: '100%' }}
              decimalSeparator={','}
              size="large"
            />
          </Form.Item>
          <Form.Item
            name="data_vencimento"
            label="Data de vencimento"
            rules={[
              {
                required: true,
                message: 'Informe a data de vencimento da cobrança'
              }
            ]}>
            <DatePicker
              size="large"
              format={'DD/MM/YYYY'}
              locale={locale}
              defaultValue={moment(new Date(data.dataVencimento))}
              disabledDate={(d) =>
                !d.isBetween(
                  moment(new Date()).subtract(1, 'day'),
                  moment(new Date(data.dataVencimento)).add(15, 'day')
                )
              }
            />
          </Form.Item>
          <Form.Item
            label="Descrição"
            name="desc_cobranca"
            rules={[
              {
                required: true,
                message: 'Informe a descrição do pagamento'
              }
            ]}>
            {!!textoParcela && <TextArea defaultValue={textoParcela} rows={6} />}
          </Form.Item>
        </>
      )}
    </Form>
  );
};

export default forwardRef(CreateCobranca);
