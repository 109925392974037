import Cookies from 'js-cookie';

class ContractClient {
  constructor() {
    this.urlApi = process.env.REACT_APP_URL_API;
    this.carteira = localStorage.getItem('carteira');
  }

  onError = (error) => {};

  get_headers() {
    const csrf = Cookies.get('XSRF-TOKEN');
    return {
      'X-XSRF-TOKEN': csrf,
      Authorization: process.env.REACT_APP_TOKEN_API
    };
  }

  async getContract(contract) {
    const data = fetch(this.urlApi + '/api/legacy/contrato/exibir/' + contract, {
      headers: this.get_headers()
    })
      .then((res) => res.json())
      .then((data) => {
        return data;
      })
      .catch((err) => {
        return {
          error:
            'Erro ao tentar se comunicar com o servidor. Por favor, tente novamente ou entre em contato conosco.'
        };
      });
    return data;
  }

  async getContracts(current = 1, pageSize = 10, searchTerms = {}) {
    let query;

    let searchParams = [
      'carteira=' + this.carteira,
      'currentPage=' + current,
      'pageSize=' + pageSize
    ];
    if (searchTerms && searchTerms.contrato) {
      searchParams.push('contrato=' + searchTerms.contrato);
    }
    if (searchTerms && searchTerms.cpfCnpj) {
      searchParams.push('cpfCnpj=' + searchTerms.cpfCnpj);
    }

    if (searchParams.length > 1) {
      query = '?' + searchParams.join('&');
    } else if (searchParams.length === 1) {
      query = '?' + searchParams[0];
    } else {
      query = '';
    }

    const data = fetch(this.urlApi + '/api/legacy/contratos/listar' + query, {
      headers: this.get_headers()
    })
      .then((res) => res.json())
      .then((data) => {
        return data;
      })
      .catch((err) => {
        return {
          error: err.message
        };
      });
    return data;
  }

  async gerarAcordo(dataAcordo, idcontrato) {
    const data = fetch(this.urlApi + '/api/legacy/acordo/gerar', {
      headers: this.get_headers(),
      method: 'POST',
      body: JSON.stringify(dataAcordo)
    })
      .then((res) => res.json())
      .then((data) => {
        return data;
      })
      .catch((err) => {
        return {
          error:
            'Erro ao tentar se comunicar com o servidor. Por favor, tente novamente ou entre em contato conosco.'
        };
      });
    return data;
  }

  async getAcordo(idAcordo) {
    const data = fetch(this.urlApi + '/api/legacy/acordo/exibir/' + idAcordo, {
      headers: this.get_headers()
    })
      .then((res) => res.json())
      .then((data) => {
        return data;
      })
      .catch((err) => {
        return {
          error:
            'Erro ao tentar se comunicar com o servidor. Por favor, tente novamente ou entre em contato conosco.'
        };
      });
    return data;
  }

  async salvarAcordo(idAcordo) {
    const data = fetch(this.urlApi + '/api/legacy/acordo/salvar/' + idAcordo, {
      headers: this.get_headers()
    })
      .then((res) => res.json())
      .then(({ success, message }) => {
        return {
          success: success,
          message: message
        };
      })
      .catch((err) => {
        return {
          success: false,
          error:
            'Erro ao tentar se comunicar com o servidor. Por favor, tente novamente ou entre em contato com o administrador.'
        };
      });
    return data;
  }

  async changeAcordoStatus(idAcordo, idStatus) {
    const data = fetch(this.urlApi + '/api/legacy/acordo/status/' + idAcordo + '/' + idStatus, {
      headers: this.get_headers()
    })
      .then((res) => res.json())
      .then(({ success, message }) => {
        return {
          success: success,
          message: message
        };
      })
      .catch((err) => {
        return {
          success: false,
          error:
            'Erro ao tentar se comunicar com o servidor. Por favor, tente novamente ou entre em contato com o administrador.'
        };
      });
    return data;
  }

  async updateParcela(
    idParcela,
    IdIntegracao,
    situacao,
    TituloCodigoBarras,
    UrlBoleto,
    TituloNossoNumero
  ) {
    const dataPost = {
      idParcela: idParcela,
      IdIntegracao: IdIntegracao,
      situacao: situacao,
      TituloCodigoBarras: TituloCodigoBarras,
      UrlBoleto: UrlBoleto,
      TituloNossoNumero: TituloNossoNumero
    };

    const data = fetch(this.urlApi + '/api/legacy/parcela/update/' + idParcela, {
      headers: this.get_headers(),
      method: 'POST',
      body: JSON.stringify(dataPost)
    })
      .then((res) => res.json())
      .then(({ success, message }) => {
        return {
          success: success,
          message: message
        };
      })
      .catch((err) => {
        return {
          success: false,
          error:
            'Erro ao tentar se comunicar com o servidor. Por favor, tente novamente ou entre em contato com o administrador.'
        };
      });
    return data;
  }

  async getAcordos(current = 1, pageSize = 100, searchTerms = {}) {
    let query;

    let searchParams = [
      'carteira=' + this.carteira,
      'currentPage=' + current,
      'pageSize=' + pageSize
    ];

    if (searchTerms && searchTerms.contrato) {
      searchParams.push('contrato=' + searchTerms.contrato);
    }
    if (searchTerms && searchTerms.status) {
      searchParams.push('status=' + searchTerms.status);
    }
    if (searchTerms && searchTerms.situacao) {
      searchParams.push('situacao=' + searchTerms.situacao);
    }
    if (searchTerms && searchTerms.acordo) {
      searchParams.push('acordo=' + searchTerms.acordo);
    }
    if (searchTerms && searchTerms.cpfCnpj) {
      searchParams.push('cpfCnpj=' + searchTerms.cpfCnpj);
    }
    if (searchTerms && searchTerms.nome) {
      searchParams.push('nome=' + searchTerms.nome);
    }

    if (searchParams.length > 1) {
      query = '?' + searchParams.join('&');
    } else if (searchParams.length === 1) {
      query = '?' + searchParams[0];
    } else {
      query = '';
    }

    const data = fetch(this.urlApi + '/api/legacy/acordos/listar' + query, {
      headers: this.get_headers()
    })
      .then((res) => res.json())
      .then((data) => {
        return data;
      })
      .catch((err) => {
        return {
          error: err.message
        };
      });
    return data;
  }

  async getParcelas(current = 1, pageSize = 10, searchTerms = {}) {
    let query;

    let searchParams = [
      'carteira=' + this.carteira,
      'currentPage=' + current,
      'pageSize=' + pageSize
    ];

    if (searchParams.length > 1) {
      query = '?' + searchParams.join('&');
    } else if (searchParams.length === 1) {
      query = '?' + searchParams[0];
    } else {
      query = '';
    }

    const data = fetch(this.urlApi + '/api/legacy/parcelas/listar' + query, {
      method: 'POST',
      body: JSON.stringify(searchTerms),
      headers: this.get_headers()
    })
      .then((res) => res.json())
      .then((data) => {
        return data;
      })
      .catch((err) => {
        return {
          error: err.message
        };
      });
    return data;
  }

  async salvarRemessa(dadosRemessa) {
    const data = fetch(this.urlApi + '/api/legacy/remessa/salvar/', {
      method: 'POST',
      body: JSON.stringify(dadosRemessa)
    })
      .then((res) => res.json())
      .then((data) => {
        return data;
      })
      .catch((err) => {
        return {
          error: err.message
        };
      });
    return data;
  }

  async updateParcelaByAcordo(paga, idAcordo, numeroParcela) {
    const dataPost = {
      idAcordo: idAcordo,
      numeroParcela: numeroParcela,
      paga: paga
    };

    const data = fetch(this.urlApi + '/api/legacy/parcela/pay', {
      headers: this.get_headers(),
      method: 'POST',
      body: JSON.stringify(dataPost)
    })
      .then((res) => res.json())
      .then(({ success, message }) => {
        return {
          success: success,
          message: message
        };
      })
      .catch((err) => {
        return {
          success: false,
          error:
            'Erro ao tentar se comunicar com o servidor. Por favor, tente novamente ou entre em contato com o administrador.'
        };
      });
    return data;
  }

  async updateParcelaByIdParcela(dataPagamento, idParcela) {
    const dataPost = {
      parcela_id: idParcela,
      data_pagamento: dataPagamento,
      paga: true
    };

    const data = fetch(this.urlApi + '/api/legacy/parcela/update-pagamento', {
      headers: this.get_headers(),
      method: 'POST',
      body: JSON.stringify(dataPost)
    })
      .then((res) => res.json())
      .then(({ success, message }) => {
        return {
          success: success,
          message: message
        };
      })
      .catch((err) => {
        return {
          success: false,
          error:
            'Erro ao tentar se comunicar com o servidor. Por favor, tente novamente ou entre em contato com o administrador.'
        };
      });
    return data;
  }

  async updateSituacaoAcordo(situacao, idAcordo) {
    const data = fetch(
      this.urlApi + '/api/legacy/acordo/update-situacao/' + idAcordo + '/' + situacao,
      {
        headers: this.get_headers(),
        method: 'GET'
      }
    )
      .then((res) => res.json())
      .then(({ success, message }) => {
        return {
          success: success,
          message: message
        };
      })
      .catch((err) => {
        return {
          success: false,
          error:
            'Erro ao tentar se comunicar com o servidor. Por favor, tente novamente ou entre em contato com o administrador.'
        };
      });
    return data;
  }

  async updateStatusSerasa(statusSerasa, idAcordo) {
    const data = fetch(
      this.urlApi + '/api/legacy/acordo/update-serasa/' + idAcordo + '/' + statusSerasa,
      {
        headers: this.get_headers(),
        method: 'GET'
      }
    )
      .then((res) => res.json())
      .then(({ success, message }) => {
        return {
          success: success,
          message: message
        };
      })
      .catch((err) => {
        return {
          success: false,
          error:
            'Erro ao tentar se comunicar com o servidor. Por favor, tente novamente ou entre em contato com o administrador.'
        };
      });
    return data;
  }

  async getParcelasAtrasadas() {
    const data = fetch(this.urlApi + '/getParcelasAtrasadas.php')
      .then((res) => res.json())
      .then((data) => {
        return data;
      })
      .catch((err) => {
        return {
          error:
            'Erro ao tentar se comunicar com o servidor. Por favor, tente novamente ou entre em contato conosco.'
        };
      });
    return data;
  }

  async getAcordosLiquidados() {
    const data = fetch(this.urlApi + '/getAcordosLiquidados.php')
      .then((res) => res.json())
      .then((data) => {
        return data;
      })
      .catch((err) => {
        return {
          error:
            'Erro ao tentar se comunicar com o servidor. Por favor, tente novamente ou entre em contato conosco.'
        };
      });
    return data;
  }

  async sincBoletos() {
    const data = fetch(this.urlApi + '/sincroniza_boletos.php')
      .then((res) => res.json())
      .then((data) => {
        return data;
      })
      .catch((err) => {
        return {
          error:
            'Erro ao tentar se comunicar com o servidor. Por favor, tente novamente ou entre em contato conosco.'
        };
      });
    return data;
  }
}

export default ContractClient;
