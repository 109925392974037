import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Layout, Breadcrumb } from 'antd';

import Contrato from '../views/Contrato';
import InstallmentsList from '../components/InstallmentsList';
import Loading from '../components/Loading';

import ContractClient from '../client/api';
import PlugBoletoClient from '../client/plugboleto';
import { Api } from 'client/lib/api';
import moment from 'moment';
const { Content } = Layout;

function ContratoPage() {
  const { idcontrato } = useParams();

  const [dataContrato, setDataContrato] = useState();
  const [acordo, setAcordo] = useState();

  const client = new ContractClient();
  const clientBoleto = new PlugBoletoClient();


  const generateAcordo = async (dataAcordo) => {
    Api()
      .agreements.generateAgreement(dataAcordo)
      .then(() => {
        setAcordo(data);
      });
  };

  const changeAcordoStatus = async (idAcordo, idStatus = 3) => {
    await Api()
      .agreements.updateStatus(idAcordo, idStatus)
      .then((data) => {
        return data;
      });
  };


  async function getContrato(idacordo) {
    await Api()
      .contracts.getContract(idacordo)
      .then((response) => setDataContrato(response))
      .catch((error) => {
        console.error(error);

      });
  }

  useEffect(() => {
    getContrato(idcontrato);
  }, [idcontrato]);

  return (
    <Layout>
      <Breadcrumb className="no-print">
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Detalhe do contrato</Breadcrumb.Item>
      </Breadcrumb>
      <Content
        className="site-layout-background"
        style={{
          padding: 0,
          margin: 0,
          minHeight: 280,
          background: '#fff'
        }}>
        {dataContrato && Object.keys(dataContrato).length > 0 ? (
          <Contrato
            data={dataContrato}
            changeAcordoStatus={changeAcordoStatus}
            generateAcordo={generateAcordo}
          />
        ) : (
          <Loading label="Carregando..." />
        )}

        <a id="simulador-resultado" />

        {acordo && (
          <InstallmentsList
            acordo={acordo}
            dataContrato={dataContrato}
            salvarAcordo={changeAcordoStatus}
          />
        )}
      </Content>
    </Layout>
  );
}

export default ContratoPage;
