import { Button, Popconfirm, Space, Table } from 'antd';
import { useEffect, useState } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { Situacao, Status } from './helpers';

function AcordosList({ data, onChange, changeAcordoStatus }) {
  const { data: acordos, current_page: page, total, per_page } = data;

  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(per_page);
  const [searchTerms, setSearchTerms] = useState();
  const [acordosList, setAcordosList] = useState(acordos);

  let initPagination = {
    current: page,
    total: total,
    pageSize,
    showTotal: (total, range) => `Total: ${total}`
  };

  useEffect(() => {
    setLoading(false);
  }, [data]);

  useEffect(() => {
    setAcordosList(acordos);
  }, [acordos]);

  const handleTableChange = async (pagination, filters, sorter) => {
    setLoading(true);

    let current = pagination.current;

    await onChange(current, pagination.pageSize);
    setLoading(false);
  };

  const handleDelete = async (idAcordo) => {
    await changeAcordoStatus(idAcordo, 6).then(() => {
      const dataSource = [...acordosList];
      setAcordosList(dataSource.filter((item) => item.id !== idAcordo));
    });
  };

  const columns = [
    {
      title: '',
      key: 'operation',
      render: (_, record) => (
        <>
          <Popconfirm
            title="Tem certeza que você quer excluir?"
            onConfirm={() => handleDelete(record.id)}>
            <Button icon={<DeleteOutlined />} />
          </Popconfirm>
        </>
      )
    },
    {
      title: 'id',
      key: 'id',
      render: (text, record) => (
        <Space size="middle">
          <a href={`${process.env.PUBLIC_URL}/acordo/${record.id}`}>{record.id}</a>
        </Space>
      )
    },
    {
      title: 'contrato',
      dataIndex: 'contrato',
      render: (text, record) => (
        <Space size="middle">
          <a href={`${process.env.PUBLIC_URL}/contrato/${record.acordo_contrato[0].id}`}>
            {record.acordo_contrato[0].contrato}
          </a>
        </Space>
      )
    },
    {
      title: 'devedor',
      dataIndex: 'devedor',
      render: (_, record) => {
        return (
          <div className="devedor-name">
            <p>
              <b>{record?.acordo_contrato[0].devedor?.nome}</b>
              <br />
              {record?.acordo_contrato[0].devedor?.email}
            </p>
          </div>
        );
      }
    },
    {
      title: 'data de criação',
      key: 'dataCriacao',
      dataIndex: 'dataCriacao',
      render: (_, record) => {
        return (
          <div>
            {new Date(record.dataCriacao).toLocaleDateString('pt-Br', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric'
            })}
          </div>
        );
      }
    },
    {
      title: 'situação pagamento',
      key: 'situacao',
      render: (_, record) => {
        return <Situacao situacao={record.situacao} />;
      }
    },
    {
      title: 'status',
      key: 'status',
      render: (_, record) => {
        return <Status status={record.status} />;
      }
    },
    {
      title: 'ativo',
      key: 'ativo',
      render: (_, record) => {
        return (
          <div>
            <b>{record.active == 'ATIVO' ? 'Sim' : 'Não'}</b>
          </div>
        );
      }
    }
  ];

  return (
    <>
      <h3>
        Total de acordos com os filtros selecionados: <b>{total}</b>
      </h3>
      <Table
        columns={columns}
        dataSource={acordosList}
        pagination={initPagination}
        onChange={handleTableChange}
        loading={loading}
        rowKey="id"
      />
    </>
  );
}

export default AcordosList;
