import { Avatar, Button, Dropdown, Layout, Menu, MenuProps, Tooltip } from 'antd';
import React, { useState, useEffect } from 'react';
import mainLogo from '../../assets/images/logo.png';
import { useAuth } from '../../context/AuthContext';
import * as S from './styles';
import {
  AppstoreOutlined,
  ContainerOutlined,
  DesktopOutlined,
  MailOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PieChartOutlined,
  LogoutOutlined,
  KeyOutlined,
  UserOutlined
} from '@ant-design/icons';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group'
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem('Option 1', '1', <PieChartOutlined />),
  getItem('Option 2', '2', <DesktopOutlined />),
  getItem('Option 3', '3', <ContainerOutlined />),

  getItem('Navigation One', 'sub1', <MailOutlined />, [
    getItem('Option 5', '5'),
    getItem('Option 6', '6'),
    getItem('Option 7', '7'),
    getItem('Option 8', '8')
  ]),

  getItem('Navigation Two', 'sub2', <AppstoreOutlined />, [
    getItem('Option 9', '9'),
    getItem('Option 10', '10'),

    getItem('Submenu', 'sub3', null, [getItem('Option 11', '11'), getItem('Option 12', '12')])
  ])
];

export const Header: React.FC = () => {
  const { signOut, userInfo } = useAuth();

  const handleMenuClick: MenuProps['onClick'] = ({ key }) => {
    if (`${key}` == '2') signOut();
    else if (`${key}` == '1') window.location.href = '/user/profile';
  };

  const menu = (
    <Menu
      onClick={handleMenuClick}
      items={[
        {
          label: 'Perfil',
          key: '1',
          icon: <KeyOutlined />
        },
        {
          label: 'sair',
          key: '2',
          icon: <LogoutOutlined />
        }
      ]}
    />
  );

  return (
    <S.Wrapper>
      <Layout.Header className="header no-print">
        <div className="logo">
          <img height="54" src={mainLogo} alt="logo" />
        </div>

        {/*<div> <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
         {userInfo.name} 
        </div>
        <Button
        size="small"
        style={{ margin: '0 16px', verticalAlign: 'middle' }}
        onClick={signOut}
      >
      Sair
      </Button>
 */}
        <div>
          <Dropdown overlay={menu} placement="bottomLeft" arrow>
            <Button icon={<UserOutlined />}>{userInfo.name}</Button>
          </Dropdown>
        </div>
        {/*<div style={{ width: 256 }}>
      <Button type="primary" onClick={toggleCollapsed} style={{ marginBottom: 16 }}>
        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </Button>
      <Menu
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        mode="inline"
        theme="dark"
        inlineCollapsed={collapsed}
        items={items}
      />
</div>*/}
      </Layout.Header>
    </S.Wrapper>
  );
};
