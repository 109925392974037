import { Button, Checkbox, Form, Input, Select } from 'antd';
import * as S from './styles';
import { v4 as uuid } from 'uuid';
import { useEffect, useState } from 'react';
import { Api } from 'client/lib/api';
import { filterOptions, filterSituacaoOptions } from '../AcordosList/helpers';
import { SearchOutlined } from '@ant-design/icons';

export type AcordosFilterProps = {
  onSubmitSearch: (searchTerms: any) => void;
};

export const AcordosFilter: React.FC<AcordosFilterProps> = ({ onSubmitSearch }) => {
  const [form] = Form.useForm();
  const [selectedLegalType, setSelectedLegalType] = useState('extra_judicial');
  const [legalTypes, setLegalTypes] = useState<string[]>([]);

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      Api()
        .agreements.getLegalTypes()
        .then((response) => setLegalTypes(response));
    }
    return () => {
      isSubscribed = false;
    };
  }, []);

  return (
    <S.Wrapper>
      <Form form={form} layout="inline" onFinish={onSubmitSearch} className="grid-form">
        <Form.Item name="contrato">
          <Input placeholder="Número do contrato" />
        </Form.Item>
        <Form.Item name="acordo">
          <Input placeholder="Número do acordo" />
        </Form.Item>
        <Form.Item name="cpfCnpj">
          <Input type="text" placeholder="CPF ou CNPJ" />
        </Form.Item>
        <Form.Item name="nome">
          <Input type="text" placeholder="Nome" />
        </Form.Item>
        <Form.Item name="tipoLegal">
          <Select
            onChange={(value) => setSelectedLegalType(value)}
            optionLabelProp="title"
            value={selectedLegalType}
            placeholder="Tipo do acordo">
            {legalTypes.length &&
              legalTypes.map((type) => (
                <Select.Option key={uuid()} title={String(type).replace('_', '-')} value={type}>
                  <div className="label-type">{String(type).replace('_', '-')}</div>
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item name="status">
          <Select placeholder="Status do acordo">
            {filterOptions.length &&
              filterOptions.map((option) => (
                <Select.Option key={uuid()} title={option.label} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item name="situacao">
          <Select placeholder="Situacão do acordo">
            {filterSituacaoOptions.length &&
              filterSituacaoOptions.map((option) => (
                <Select.Option key={uuid()} title={option.label} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
          </Select>
          </Form.Item>

        <Form.Item name="active">
          <Checkbox.Group style={{ marginRight: '20px' }} options={[{label: 'Somente Ativos',value: 'ATIVO'}]} />
        </Form.Item>

      

        <Form.Item shouldUpdate>
          {() => (
            <Button icon={<SearchOutlined />} type="primary" htmlType="submit">
              Buscar
            </Button>
          )}
        </Form.Item>
      </Form>
    </S.Wrapper>
  );
};
