import React from 'react';
import PropTypes from 'prop-types';

import ContractList from '../components/ContractsList/index';

const Contratos = ({ data, onChange }) => {
  return (
    <>
      <ContractList data={data} onChange={onChange} />
    </>
  );
};

Contratos.propTypes = {
  data: PropTypes.object
};

export default Contratos;
