import { Menu as AntdMenu } from 'antd';
import { Api } from 'client/lib/api';
import { CarteiraMetodoPagamento } from 'client/lib/pagamento/models';
import CancelCobranca, {
  CancelCobrancaHandler
} from 'components/AcordoDetails/templates/CancelCobranca';
import CreateCobranca, {
  CreateCobrancaHandler
} from 'components/AcordoDetails/templates/CreateCobranca';
import CreatePagamento, { CreatePagamentoHandler } from 'components/AcordoDetails/templates/CreatePagamento';
import HistoricoPagamento, { HistoricoPagamentoHandler } from 'components/AcordoDetails/templates/HistoricoPagamento';
import { IParcela } from 'components/AcordoDetails/templates/models';
import { DataModalType, useModalContext } from 'context/ModalContext';
import { useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

export type ActionMenuProps = {
  dataParcela: IParcela;
  getData: (params: any) => void;
  getDataParams: any;
};

export const ActionMenu: React.FC<ActionMenuProps> = ({ dataParcela, getData, getDataParams }) => {
  const options = [];
  const { setDataModal } = useModalContext();

  const createCobrancaTemplateRef = useRef<CreateCobrancaHandler>(null);
  const cancelCobrancaTemplateRef = useRef<CancelCobrancaHandler>(null);
  const createPagamentoTemplateRef = useRef<CreatePagamentoHandler>(null);
  const historicoPagamentoTemplateRef = useRef<HistoricoPagamentoHandler>(null);

  const [carteiraMetodoPagamentoData, setCarteiraMetodoPagamentoData] =
    useState<CarteiraMetodoPagamento[]>();

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      Api()
        .pagamento.getCarteiraMetodoPagamento(dataParcela.acordo_id)
        .then((response) => setCarteiraMetodoPagamentoData(response));
    }
    return () => {
      isSubscribed = false;
    };
  }, []);


  const params: [any] = getDataParams;

  function handleCreateCobrancaClick() {
    const data: DataModalType = {
      title: `Criar cobrança - Parcela: ${dataParcela.numeroParcela}`,
      children: (
        <CreateCobranca
          ref={createCobrancaTemplateRef}
          data={{ ...dataParcela, carteiraMetodoPagamento: carteiraMetodoPagamentoData }}
        />
      ),
      visible: true,
      onOk: () => {
        createCobrancaTemplateRef?.current?.submitForm();
      },
      onCancel: () => setDataModal({ visible: false, children: null }),
      onFinish: () => {
        getData(...params);
      }
    };
    setDataModal(data);
  }

  function handleCancelCobrancaClick() {
    const data: DataModalType = {
      title: `Cancelar cobrança - Parcela: ${dataParcela.numeroParcela}`,
      children: (
        <CancelCobranca
          ref={cancelCobrancaTemplateRef}
          data={{ ...dataParcela, carteiraMetodoPagamento: carteiraMetodoPagamentoData }}
        />
      ),
      visible: true,
      onOk: () => {
        cancelCobrancaTemplateRef?.current?.submitForm();
      },
      onCancel: () => setDataModal({ visible: false, children: null }),
      onFinish: () => {
        getData(...params);
      }
    };
    setDataModal(data);
  }


  function handleCreatePagamentoClick() {
    const data: DataModalType = {
      title: `Criar pagamento - Parcela: ${dataParcela.numeroParcela}`,
      children: (
        <CreatePagamento
          ref={createPagamentoTemplateRef}
          data={{ ...dataParcela, carteiraMetodoPagamento: carteiraMetodoPagamentoData }}
        />
      ),
      visible: true,
      onOk: () => {
        createPagamentoTemplateRef?.current?.submitForm();
      },
      onCancel: () => setDataModal({ visible: false, children: null }),
      onFinish: () => {
        getData(...params);
      }
    };
    setDataModal(data);
  }


  function handleHistoricoPagamentoClick() {
    const data: DataModalType = {
      title: `Baixar Pagamento - Parcela: ${dataParcela.numeroParcela}`,
      children: (
        <HistoricoPagamento
          ref={historicoPagamentoTemplateRef}
          data={{ ...dataParcela }}
          getData={getData}
          getDataParams={getDataParams}
        />
      ),
      visible: true,
      onCancel: () => setDataModal({ visible: false, children: null }),
      onOk: () => setDataModal({ visible: false, children: null }),
      onFinish: () => {
        getData(...params);
      },
      width: '80%',
    };
    setDataModal(data);
  }


  if (dataParcela.actions?.includes('historico_pagamento')) {
    options.push({
      label: 'Histórico de Pagamento',
      key: uuid(),
      onClick: handleHistoricoPagamentoClick
    });
  }
  if (dataParcela.actions?.includes('criar_cobranca')) {
    options.push({
      label: ' Criar cobrança',
      key: uuid(),
      onClick: handleCreateCobrancaClick
    });
  }

  if (dataParcela.actions?.includes('cancelar_cobranca')) {
    options.push({
      label: ' Cancelar cobrança',
      key: uuid(),
      onClick: handleCancelCobrancaClick
    });
  }

  if (dataParcela.actions?.includes('criar_pagamento')) {
    options.push({
      label: ' Criar pagamento',
      key: uuid(),
      onClick: handleCreatePagamentoClick
    });
  }


  return options.length ? <AntdMenu items={options}></AntdMenu> : null;
};
