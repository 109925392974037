import { Form, message } from 'antd';
import { Api } from 'client/lib/api';
import { useModalContext } from 'context/ModalContext';
import { forwardRef, useCallback, useImperativeHandle } from 'react';

export type EstornarPagamentoHandler = {
  submitForm: () => void;
};

export type EstornarPagamentoProps = {
  data: any;
};

const EstornarPagamento: React.ForwardRefRenderFunction<
  EstornarPagamentoHandler,
  EstornarPagamentoProps
> = ({ data }, ref) => {
  const { hideModal, dataModal, onLoading, abortLoading } = useModalContext();
  const [form] = Form.useForm();
  
  
  const submitForm = useCallback(() => {
      const formValues = form.getFieldsValue();
      const body = {
        force: formValues.force
      };

      onLoading();
      Api()
        .pagamento.reversePagamento(data.id, body)
        .then(() => {
          message.success('Pagamento estornado!');
          if (dataModal.onFinish) {
            dataModal?.onFinish();
          }
          hideModal();
        })
        .catch(() => abortLoading());
  }, []);

  useImperativeHandle(ref, () => {
    return {
      submitForm
    };
  });

  return (
    <Form form={form}>
      Têm certeza que deseja estornar o Pagamento?
    </Form>
  );
};

export default forwardRef(EstornarPagamento);
