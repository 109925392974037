import React, { useState, useEffect } from 'react';

import {
  Table,
  Space,
  Form,
  Input,
  Button,
  Checkbox,
  message as antMessage,
  DatePicker,
  Tag,
  Card,
  Dropdown
} from 'antd';

import moment from 'moment';
import locale from 'antd/es/date-picker/locale/pt_BR';

const { RangePicker } = DatePicker;

import { SearchOutlined, DeleteOutlined } from '@ant-design/icons';
import { ActionMenu } from 'components/AcordoDetails/components/ActionMenu';
import { Api } from 'client/lib/api';
import { RenderBoletoActionButtons } from '../helpers/RenderBoletoActionButtons';
function ParcelasList({ data, onChange }) {
  const { data: parcelas, current_page: page, total } = data;

  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(50);
  const [searchTerms, setSearchTerms] = useState();
  const [dataInicio, setDataInicio] = useState('');
  const [dataFim, setDataFim] = useState('');
  const [selectedFilters, setSelectedFilters] = useState();
  const [form] = Form.useForm();

  let initPagination = {
    current: page,
    total: total,
    pageSize,
    showTotal: (total, range) => `Total: ${total}`
  };

  const dateFormat = 'DD/MM/YYYY';

  const filterOptions = [
    { label: 'Pagas', value: 'pagas' },
    { label: 'Vencidas', value: 'vencidas' },
    { label: 'A vencer', value: 'avencer' }
  ];

  useEffect(() => {
    setLoading(false);
    if (localStorage.getItem('searchTerms')) {
      setSelectedFilters(JSON.parse(localStorage.getItem('searchTerms')));
    }
  }, [data]);

  const handleTableChange = async (pagination, filters, sorter) => {
    setLoading(true);
    let current = pagination.current;
    await onChange(
      current,
      pagination.pageSize,
      localStorage.getItem('searchTerms') != null
        ? JSON.parse(localStorage.getItem('searchTerms'))
        : null
    );
    setLoading(false);
  };

  const forcarProcessamento = async (idPagamento) => {
    Api()
      .pagamento.forcarProcessamento(idPagamento)
      .then((response) => onFinishSearch(localStorage.getItem('searchTerms')));
  };

  const onFinishSearch = async (searchTerms) => {
    setLoading(true);
    const { filtros } = searchTerms;

    if (dataInicio !== '') {
      searchTerms.dataVencimentoInicio = dataInicio;
    }

    if (dataFim !== '') {
      searchTerms.dataVencimentoFim = dataFim;
    }

    if (filtros) {
      searchTerms.filtros = filtros.reduce((acc, curr) => ((acc[curr] = true), acc), {});
    }

    setSearchTerms(searchTerms);
    localStorage.setItem('searchTerms', JSON.stringify(searchTerms));
    await onChange(1, pageSize, searchTerms);
    setLoading(false);
  };

  const showFilters = (filters) => {
    let output = [];

    const dictLabels = {
      dataVencimentoInicio: 'Data início',
      dataVencimentoFim: 'Data fim',
      acordo: 'Acordo Id'
    };

    if (filters) {
      Object.keys(filters).map((f) => {
        if (typeof filters[f] === 'object' && filters[f] !== null) {
          Object.keys(filters[f]).map((sf, index) => {
            if (isNaN(sf)) {
              const label = filterOptions.filter((fo) => fo.value === sf)[0].label;
              output.push(<Tag>{`${label}`}</Tag>);
            }
          });
        } else {
          output.push(<Tag>{`${dictLabels[f]}: ${filters[f]}`}</Tag>);
        }
      });
    }
    return output;
  };

  const columns = [
    {
      title: 'Contrato',
      dataIndex: 'contrato',
      render: (text, record) => (
        <Space size="middle">
          <a
            style={{ textAlign: 'center', margin: 0 }}
            href={`${process.env.PUBLIC_URL}/contrato/${record.acordo.contrato_id}`}>
            {record.contrato_orig}
          </a>
        </Space>
      )
    },
    {
      title: 'Acordo',
      dataIndex: 'acordo',
      render: (text, record) => (
        <Space size="middle">
          <a
            style={{ textAlign: 'center', margin: 0 }}
            href={`${process.env.PUBLIC_URL}/acordo/${record.acordo_id}`}>
            {record.acordo_id}
          </a>
        </Space>
      )
    },
    {
      title: 'Parcela',
      dataIndex: 'numeroParcela',
      render: (_, record) => {
        return <p style={{ textAlign: 'center', margin: 0 }}>{record.numeroParcela}</p>;
      }
    },
    {
      title: 'Vencimento',
      dataIndex: 'dataVencimento',
      render: (_, record) => {
        let color = '#000';
        if (record.vencida) {
          color = 'red';
        }
        if (record.paga) {
          color = 'green';
        }
        const data = record.dataVencimento ? moment(record.dataVencimento).format(dateFormat) : '';
        return <p style={{ textAlign: 'center', color, margin: 0 }}>{data}</p>;
      }
    },
    {
      title: 'Valor',
      dataIndex: 'valor',
      render: (_, record) => {
        return (
          <p style={{ textAlign: 'center', margin: 0 }}>
            {new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
              minimumFractionDigits: 2
            }).format(record.valor)}
          </p>
        );
      }
    },
    {
      title: 'Vencimento Cobrança',
      dataIndex: 'vencimentoCobranca',
      render: (_, record) => {
        let color = '#000';
        if (record.vencida) {
          color = 'red';
        }
        if (record.paga) {
          color = 'green';
        }
        const data = record?.cobranca[0]?.data_vencimento
          ? moment(record?.cobranca[0]?.data_vencimento).format(dateFormat)
          : '-';
        return <p style={{ textAlign: 'center', color, margin: 0 }}>{data}</p>;
      }
    },
    {
      title: 'Valor Cobrança',
      dataIndex: 'valorCobranca',
      render: (_, record) => {
        return (
          <p style={{ textAlign: 'center', margin: 0 }}>
            {record?.cobranca[0]?.parcela_cobranca?.valor_parcela
              ? new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 2
              }).format(record?.cobranca[0]?.parcela_cobranca?.valor_parcela)
              : '-'}
          </p>
        );
      }
    },
    {
      title: 'Método Pagamento',
      dataIndex: 'info_boleto',
      render: (_, record) => {
        return (
          <p style={{ textAlign: 'center', margin: 0 }}>
            {record?.cobranca[0]?.pagamento?.carteira_metodo_pagamento?.metodo_pagamento?.nome || '-'}
          </p>
        );
      }
    },
    {
      title: 'Informações de pagamento',
      dataIndex: 'info_boleto',
      render: (_, record) => {
        if (record?.cobranca[0]?.pagamento)
          return <RenderBoletoActionButtons data={record?.cobranca[0]?.pagamento} onForceProcess={forcarProcessamento} />
        return <p style={{ textAlign: 'center', margin: 0 }}>{'-'}</p>;
      }
    },
    {
      title: 'Integração',
      dataIndex: 'idIntegracao',
      render: (_, record) => {
        const boletoJson =
          record?.cobranca[0]?.pagamento?.dados_metodo_pagamento &&
            record.cobranca[0].pagamento.dados_metodo_pagamento != null
            ? record.cobranca[0].pagamento.dados_metodo_pagamento
            : null;
        return <p style={{ textAlign: 'center', margin: 0 }}>{boletoJson?.numero || '-'} </p>;
      }
    },
    {
      title: 'Situação parcela',
      dataIndex: 'situacaoParcela',
      render: (_, record) => {
        return <p style={{ textAlign: 'center', margin: 0 }}>{record.situacao || '-'}</p>;
      }
    },
    {
      title: 'Situação pagamento',
      dataIndex: 'situacaoPagamento',
      render: (_, record) => {
        return (
          <p style={{ textAlign: 'center', margin: 0 }}>
            {record?.cobranca[0]?.pagamento?.situacao || '-'}
          </p>
        );
      }
    },
    {
      title: '',
      dataIndex: '',
      render: (parcela, record) => {
        return (
          <div>
            <Space>
              <Dropdown.Button
                trigger={['click']}
                overlay={
                  <ActionMenu
                    dataParcela={{
                      ...parcela,
                      statusAcordo: record.acordo.status,
                      situacaoAcordo: record.acordo.situacao
                    }}
                    getData={onChange}
                    getDataParams={[page, pageSize, searchTerms ? searchTerms : null]}
                  />
                }
              />
            </Space>
          </div>
        );
      }
    }
  ];

  return (
    <>
      <div style={{ height: '50px' }}>
        <Form
          form={form}
          name="horizontal_login"
          layout="inline"
          onFinish={onFinishSearch}
          style={{ display: 'flex', alignItems: 'center' }}>
          <Form.Item name="filtros">
            <Checkbox.Group style={{ marginRight: '20px' }} options={filterOptions} />
          </Form.Item>

          <Form.Item name="acordo">
            <Input placeholder="Acordo" />
          </Form.Item>

          <Form.Item name="Data vencimento">
            <RangePicker
              allowEmpty={true}
              format={dateFormat}
              locale={locale}
              onChange={(_, dateStrings) => {
                setDataInicio(dateStrings[0]);
                setDataFim(dateStrings[1]);
              }}
            />
          </Form.Item>

          <Form.Item shouldUpdate>
            {() => (
              <Button icon={<SearchOutlined />} type="primary" htmlType="submit">
                Buscar
              </Button>
            )}
          </Form.Item>
        </Form>
      </div>

      {selectedFilters && Object.keys(selectedFilters).length > 0 && (
        <Card className="filters-card" style={{ padding: 0, marginBottom: 5 }}>
          <h4>Filtros:</h4>
          {showFilters(selectedFilters)}
        </Card>
      )}

      <Table
        columns={columns}
        dataSource={parcelas}
        pagination={initPagination}
        onChange={handleTableChange}
        loading={loading}
        rowKey={(record) => record.id}
        scroll={{ x: true }}
      />

      {parcelas && parcelas.length === 50 && (
        <p style={{ textAlign: 'center', marginTop: '20px' }}>
          <button
            className="ant-btn ant-btn-secondary"
            onClick={() => handleTableChangePagination(paginaAtual)}>
            Carregar mais parcelas
          </button>
        </p>
      )}
    </>
  );
}

export default ParcelasList;
