import React from 'react';
import { Navigate, Route, RouteProps } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

export type ProtectedRouteProps = {
  alias: string;
  children: React.ReactNode;
};

export const ProtectedRoute = ({ alias, children }: ProtectedRouteProps) => {
  const { userLogged, userPermissions } = useAuth();

  if (userLogged && userPermissions.includes(alias)) return children;
  else if (!userLogged || !userPermissions.includes(alias)) return <Navigate to="/system/login" />;
};
