import BaseHttpClient from '../http/AxiosHttpClient';
import { CancelCobranca, Cobranca, CreateCobranca } from './models';

export class CobrancaApi {
  private readonly _httpClient: BaseHttpClient;

  public constructor() {
    this._httpClient = new BaseHttpClient();
  }

  public async createCobranca(data: CreateCobranca): Promise<Cobranca> {
    return this._httpClient.post(`/api/1.0/cobranca/create`, data);
  }

  public async getParcelaText(id: string): Promise<string> {
    return this._httpClient.get(`/api/1.0/cobranca/text/${id}`)
  }

  public async cancelCobranca(id: string, data: CancelCobranca) {
    return this._httpClient.post(`/api/1.0/cobranca/cancel/${id}`, data)
  }

}
