import { useEffect, useRef, useState } from 'react';

import locale from 'antd/es/date-picker/locale/pt_BR';
import { useAuth } from 'context/AuthContext';
import dayjs from 'dayjs';
import 'moment/locale/pt-br';
import { v4 as uuid } from 'uuid';

import { CalendarOutlined, DeleteOutlined, PlusOutlined, ProfileOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  message
} from 'antd';
const { Option } = Select;

import moment from 'moment';
import { Api } from '../../client/lib/api';

import { UserRolesEnum } from 'constants/UserRolesEnum';
import { useNavigate } from 'react-router-dom';
import { HANDLER_ERRORS } from '../../errors';
import { SUCCESS_MESSAGES } from '../../helpers';

const FormularioPersonalizado = ({ dataContrato, fecharModal }) => {
  const { userInfo } = useAuth();
  const [form] = Form.useForm();
  const [contrato, setContrato] = useState(dataContrato);
  const [contractList, setContractList] = useState([]);
  const [selectedContractList, setSelectedContractList] = useState([]);
  const [selectedLegalType, setSelectedLegalType] = useState(getLetalTypeByUser(userInfo));
  const [legalTypes, setLegalTypes] = useState([]);
  const dateFormat = 'DD/MM/YYYY';
  const isoDateFormat = 'YYYY-MM-DD';
  const [diasEmAtraso, setDiasEmAtraso] = useState(0);
  const [valorTotalAcordo, setValorTotalAcordo] = useState(0);
  const [totalSelectedValues, setTotalSelectedValues] = useState(0);
  const [segmentosParcela, setSegmentosParcela] = useState([{ de: 1, ate: 1, valorParcela: 0 }]);
  const [ultimoAte, setUltimoAte] = useState(1);
  const [dataInstallment, setDataInstallment] = useState([]);
  const [isDataValid, setIsDataValid] = useState(true);
  const [installmentTotal, setInstallmentTotal] = useState([]);
  const [segmentosDatePickerStatus, setSegmentosDatePickerStatus] = useState([]);
  const [selectedDueDate, setSelectedDueDate] = useState([]);
  const [sdrList, setSdrList] = useState([]);
  const [closerList, setCloserList] = useState([]);
  const [selectedSDR, setSelectedSDR] = useState('');
  const [selectedCloser, setSelectedCloser] = useState(
    getBestUserRole() === UserRolesEnum.SUPER_ADMIN ? 'closer' : userInfo.id
  );

  const [telephone, setTelephone] = useState(
    dataContrato.devedor?.telefoneResidencial
      ? dataContrato.devedor?.dddResidencial + dataContrato.devedor?.telefoneResidencial
      : ''
  );
  const [bestEmail, setBestEmail] = useState(dataContrato.devedor?.email);

  const [bestPhone, setBestPhone] = useState(
    contrato.devedor?.telefoneResidencial
      ? contrato.devedor?.telefoneResidencial
      : contrato.devedor.dddResidencial + ' ' + contrato.devedor.telefoneResidencial
  );

  const [totalParcelas, setTotalParcelas] = useState(1);

  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();

  const [lastSelectedParcelaDate, setLastSelectedParcelaDate] = useState();

  const inputElement = useRef(null);

  function getLetalTypeByUser(userInfo) {
    const roles = userInfo?.roles.map((role) => role.name);
    return roles.includes('CLOSER_INTERNO_ADVOGADO') ? 'judicial' : 'extra_judicial';
  }

  const handleBestEmail = (e) => {
    setBestEmail(e.target.value);
  };

  const handleBestPhone = (e) => {
    let value = e.target.value;

    value = value.replace(/\D/g, '');
    value = value.replace(/(\d{2})(\d)/, '($1) $2');
    value = value.replace(/(\d)(\d{4})$/, '$1-$2');

    setBestPhone(value);
  };

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      Api()
        .agreements.getLegalTypes()
        .then((response) => setLegalTypes(response));
      populateClosersDropdown(selectedLegalType);
    }
    return () => {
      isSubscribed = false;
    };
  }, []);

  const handleSelectAllContracts = (e) => {
    if (e.target.checked) {
      const contracts = contractList.map((contract) => contract.contrato_id);
      setSelectedContractList(contracts);
    } else {
      setSelectedContractList([]);
    }
  };

  useEffect(() => {
    Api()
      .contracts.getContractsByDevedorIdAndCarteiraId(contrato.devedor_id, contrato.carteira_id)
      .then((response) => setContractList(response));

    if (selectedDueDate[0] === undefined) {
      setSelectedDueDate([...selectedDueDate, moment()]);
    }
  }, []);

  const handleContractClick = (contract, addContract = false) => {
    if (addContract) {
      setSelectedContractList([...selectedContractList, contract.contrato_id]);
    } else {
      setSelectedContractList(selectedContractList.filter((item) => item !== contract.contrato_id));
    }
  };

  useEffect(() => {
    let total = contractList.reduce(
      (accum, curr) => {
        if (selectedContractList.includes(curr.contrato_id)) {
          return {
            valorPendente: accum.valorPendente + curr.valorPendente,
            diasEmAtraso: Math.max(accum.diasEmAtraso, curr.diasEmAtraso)
          };
        }
        return accum;
      },
      { valorPendente: 0, diasEmAtraso: 0 }
    );

    total.valorPendente = Math.round(total.valorPendente * 100) / 100;
    setTotalSelectedValues(total.valorPendente);
    setDiasEmAtraso(total.diasEmAtraso);
  }, [selectedContractList]);

  const renderContractsCheckbox = (contractList) => {
    return contractList.map((contract) => (
      <>
        <Checkbox
          key={uuid()}
          value={contract.contrato}
          checked={selectedContractList.includes(contract.contrato_id)}
          onChange={(e) => {
            let addContract = false;
            if (e.target.checked) {
              addContract = true;
            }
            handleContractClick(contract, addContract);
          }}>
          {contract.contrato} <span style={{ color: 'red' }}>({`R$${contract.valorPendente}`})</span>{' '}
          - {contract.diasEmAtraso} dias em atraso
        </Checkbox>{' '}
        <br />
      </>
    ));
  };

  const adicionarSegmentoParcela = () => {
    const novoAte = segmentosParcela[segmentosParcela.length - 1].ate + 2;
    const novoSegmentoParcela = {
      de: segmentosParcela[segmentosParcela.length - 1].ate + 1,
      ate: novoAte,
      valorParcela: 0,
      dataVencimento: []
    };

    if (selectedDueDate.length > 0) {
      const firstDate = moment(selectedDueDate[0]);
      const totalAte = segmentosParcela[segmentosParcela.length - 1].ate;
      firstDate.add(totalAte, 'month');
      setSelectedDueDate([...selectedDueDate, firstDate]);
    }

    setSegmentosParcela([...segmentosParcela, novoSegmentoParcela]);
    setUltimoAte(novoAte);

    const lastParcelaDate = moment(lastSelectedParcelaDate).add(1, 'month').format('YYYY-MM-DD');
    const segmentos = [...segmentosParcela, novoSegmentoParcela];
    const parcelas = gerarParcelas([...segmentosParcela, novoSegmentoParcela]);
    setDataInstallment(parcelas);

    setTotalParcelas(parcelas.length);

    parcelas.sort(function (a, b) {
      const aDate = new Date(a.dataVencimento);
      const bDate = new Date(b.dataVencimento);

      if (aDate < bDate) {
        return 1;
      }
      if (aDate > bDate) {
        return -1;
      }
      return 0;
    });
    setLastSelectedParcelaDate(parcelas[0].dataVencimento);
  };

  const excluirSegmentoParcela = (index) => {
    const newSegmentosParcela = segmentosParcela.filter((_, i) => i !== index);
    setSegmentosParcela(newSegmentosParcela);

    const lastParcela = newSegmentosParcela.slice(-1);

    setTotalParcelas(lastParcela[0].ate);
  };

  const adicionarParcela = (de, ate, valorParcela) => {
    const segmentoParcela = {
      de,
      ate,
      valorParcela,
      dataVencimento: []
    };

    setDataInstallment([...dataInstallment, segmentoParcela]);

    const parcelas = gerarParcelas([...segmentosParcela, segmentoParcela]);
    setDataInstallment(parcelas);
  };

  const gerarParcelas = (segmentos) => {
    const parcelas = [];

    segmentos.forEach((segmento, index) => {
      const { de, ate, valorParcela } = segmento;

      for (let i = de; i <= ate; i++) {
        const dataVencimento = selectedDueDate[index]
          ? moment(selectedDueDate[index])
              .add(i - de, 'months')
              .format('YYYY-MM-DD')
          : moment().format('YYYY-MM-DD');

        const parcela = {
          name: i.toString(),
          valorParcela: valorParcela,
          moneyTwo: (i * valorParcela).toFixed(2).toString(),
          dataVencimento: dataVencimento
        };

        parcelas.push(parcela);
      }
    });

    return parcelas;
  };

  const previewInstallment = [
    {
      title: 'Nº parcela',
      dataIndex: 'name',
      align: 'center',
      render: (_, __, index) => index + 1
    },
    {
      title: 'Valor parcela',
      dataIndex: 'valorParcela',
      align: 'center',
      render: (valorParcela) => `R$ ${valorParcela}`
    },
    // {
    //   title: 'Valor amortização',
    //   dataIndex: 'moneyTwo',
    //   align: 'center',
    //   render: () => `R$ 0`,
    // },
    {
      title: 'Data de vencimento',
      dataIndex: 'dataVencimento',
      align: 'center',
      render: (dataVencimento) => {
        return dataVencimento ? moment(dataVencimento).format(dateFormat) : '';
      }
    }
  ];

  const InstallmentInfo = [
    {
      title: 'Total de parcelas',
      dataIndex: 'numTotalParcelas',
      align: 'center',
      render: () => totalParcelas
    },
    {
      title: 'Valor total das parcelas',
      dataIndex: 'moneyTotal',
      align: 'center',
      render: () =>
        `R$ ${dataInstallment
          .reduce((sum, installment) => sum + installment.valorParcela, 0)
          .toFixed(2)}`
    },
    // {
    //   title: 'Valor total amortização',
    //   dataIndex: 'moneyTwoTotal',
    //   align: 'center',
    //   render: () => `R$ 0`,
    // },
    {
      title: 'Valor de desconto',
      dataIndex: 'desconto',
      align: 'center',
      render: () => `R$ 0`
    }
  ];

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCloseModal = () => {
    fecharModal();
  };

  const handlePreviewClick = () => {
    setIsLoading(true);

    const { de, ate, valorParcela } = form.getFieldsValue(['de', 'ate', 'valorParcela']);
    adicionarParcela(de, ate, valorParcela);

    setTimeout(() => {
      setIsLoading(false);
      setIsModalOpen(true);
      const totalValue = dataInstallment.reduce((accum, curr) => accum + curr.valorParcela, 0);
      setInstallmentTotal([
        {
          nameTotal: 'Total de parcelas',
          moneyTotal: totalValue.toFixed(2),
          numTotalParcelas: 0
        }
      ]);
    }, 2000);
  };

  const handleAteChange = (index, value) => {
    const newSegmentosParcela = [...segmentosParcela];
    newSegmentosParcela[index].ate = value;
    setSegmentosParcela(newSegmentosParcela);
    setUltimoAte(value);

    if (index === newSegmentosParcela.length - 1) {
      setTotalParcelas(value);
    }

    const newSelectedDueDate = [...selectedDueDate];
    if (index >= newSelectedDueDate.length) {
      newSelectedDueDate.push(null);
    } else if (index < newSelectedDueDate.length - 1) {
      newSelectedDueDate.splice(index, 1);
    }
    setSelectedDueDate(newSelectedDueDate);
  };

  const disabledDate = (current) => {
    return current && current <= dayjs().subtract(1, 'day');
  };

  const handleDueDateChange = (date, index) => {
    const newSelectedDueDate = [...selectedDueDate];
    newSelectedDueDate[index] = date;

    const todasDatasVencimento = dataInstallment.flatMap((parcela) => parcela.dataVencimento);
    const isDataVencimentoAnterior = todasDatasVencimento.some((dataVencimento) => {
      return dataVencimento && date && moment(date).isBefore(dataVencimento, 'day');
    });

    const newSegmentosDatePickerStatus = [...segmentosDatePickerStatus];
    newSegmentosDatePickerStatus[index] = isDataVencimentoAnterior ? 'error' : null;

    setSelectedDueDate(newSelectedDueDate);
    setSegmentosDatePickerStatus(newSegmentosDatePickerStatus);

    setIsDataValid(!isDataVencimentoAnterior);
  };

  const handleValorTotalAcordoChange = (value) => {
    const numericValue = parseFloat(value);
    if (!isNaN(numericValue)) {
      setValorTotalAcordo(numericValue.toFixed(2));
    }
  };

  const handleValorTotalAcordoKeyDown = (event) => {
    const key = event.key;
    const isNumber = /^\d$/.test(key);
    const isSpecialKey = [
      'Backspace',
      'Delete',
      'ArrowLeft',
      'ArrowRight',
      'Tab',
      'Meta',
      'Control'
    ].includes(key);

    if (!isNumber && !isSpecialKey) {
      event.preventDefault();
    }
  };

  const handleChangeEmailsCadastrados = async (e) => {
    form.setFieldsValue({ novoEmail: e });
  };

  const handleChangeTelefonesCadastrados = async (e) => {
    form.setFieldsValue({ novoTelefone: e });
    setTelephone(e);
  };

  const initialValues = {
    contrato_id: contrato.id,
    contrato: contrato.contrato,
    novoEmail: contrato.devedor?.email.toLowerCase(),
    novoTelefone: bestPhone,
    cpfCnpj: contrato.devedor?.cpfCnpj,
    valorTotalAcordo: 0
  };

  const parcelas = dataInstallment.map((parcela) => {
    const { name, valorParcela, dataVencimento } = parcela;
    return {
      numeroParcela: name,
      valorParcela: valorParcela,
      dataVencimento: dataVencimento
    };
  });

  const handleCreateAgreement = async (values) => {
    if (selectedContractList.length === 0) {
      message.error('Selecione ao menos um contrato');
      return false;
    }

    if (selectedSDR === '' || selectedSDR === 'sdr') {
      message.error('Selecione um SDR');
      return false;
    }

    if (selectedCloser === '' || selectedCloser === 'closer') {
      message.error('Selecione um Closer');
      return false;
    }

    const body = {
      tipoLegal: selectedLegalType,
      contrato: contrato.contrato,
      contratoList: selectedContractList,
      contrato_id: contrato.id,
      cpfCnpj: contrato.devedor?.cpfCnpj,
      dataCriacao: new Date().toISOString(),
      valorTotalAcordo: valorTotalAcordo,
      parcelas: parcelas,
      qtdParcelas: totalParcelas,
      valorTotalComissao: 0,
      desconto: 0,
      motivoRejeicao: 'N/A',
      observacao: 'N/A',
      sinal: 0,
      situacao: 0,
      status: 1,
      statusSerasa: 0,
      email: contrato.devedor?.email.toLowerCase(),
      novoEmail: bestEmail,
      telefone: bestPhone,
      vencimento: parcelas[0].dataVencimento,
      entrada: 0,
      formaPagamento: parcelas.length > 1 ? 'parc' : 'avista',
      percDesconto: 0,
      valorFinal: valorTotalAcordo,
      sdr: selectedSDR,
      closer: selectedCloser
    };

    Api()
      .agreements.generateAgreement(body)
      .then((response) => {
        message.success(SUCCESS_MESSAGES.AGREEMENTS.NEW_AGREEMENT_SUCCESS);
        navigate(`/acordo/${response.id}`);
      })
      .catch((error) => {
        console.error(error);
        message.error(HANDLER_ERRORS.AGREEMENTS.GENERATE_AGREEMENT_ERROR);
      });
  };

  const handleGenerateSimulation = () => {
    form.submit();
  };

  const handleSelectedSDR = (value) => {
    setSelectedSDR(value);
  };

  const populateCloserDropdownByRole = (role) => {
    populateDropdownByRole(role, setCloserList, handleSelectedCloser);

    let roleSDR = UserRolesEnum.SDR_INTERNO;
    if (role === UserRolesEnum.CLOSER_INTERNO_ADVOGADO) {
      /**
       * TODO: Change to SDR_INTERNO_ADVOGADO
       */
      roleSDR = UserRolesEnum.CLOSER_INTERNO;
    }

    populateSDRDropdownByRole(roleSDR);
  };

  const populateSDRDropdownByRole = (role) => {
    populateDropdownByRole(role, setSdrList, handleSelectedSDR);
  };

  const populateDropdownByRole = (role, setList, handleSelected) => {
    Api()
      .user.getUserListByRole(role)
      .then((response) => {
        if (Array.isArray(response) && response.length > 0) {
          setList(response);
        } else {
          handleSelected(response.id);
        }
      });
  };

  function getBestUserRole() {
    const roles = userInfo?.roles.map((role) => role.name);
    if (roles.includes(UserRolesEnum.SUPER_ADMIN)) {
      return UserRolesEnum.SUPER_ADMIN;
    }
    if (roles.includes(UserRolesEnum.CLOSER_INTERNO_ADVOGADO)) {
      return UserRolesEnum.CLOSER_INTERNO_ADVOGADO;
    }
    if (roles.includes(UserRolesEnum.CLOSER_INTERNO)) {
      return UserRolesEnum.CLOSER_INTERNO;
    }
    if (roles.includes(UserRolesEnum.SDR_INTERNO)) {
      return UserRolesEnum.SDR_INTERNO;
    }
    return UserRolesEnum.SDR_INTERNO;
  }

  const disableDropdownByRole = () => {
    if (getBestUserRole() !== UserRolesEnum.SUPER_ADMIN) {
      return 'disabled';
    }
  };

  const populateClosersDropdown = (tipoLegal) => {
    /**
     * Verify all roles of logged user.
     * If is SUPER_ADMIN, it will call API
     */
    const roles = userInfo?.roles.map((role) => role.name);

    if (roles.includes(UserRolesEnum.SUPER_ADMIN)) {
      let role = UserRolesEnum.CLOSER_INTERNO;
      if (tipoLegal === 'judicial') {
        role = UserRolesEnum.CLOSER_INTERNO_ADVOGADO;
      }

      populateCloserDropdownByRole(role);
      return;
    } else if (roles.includes(UserRolesEnum.CLOSER_INTERNO_ADVOGADO)) {
      populateCloserDropdownByRole(UserRolesEnum.CLOSER_INTERNO_ADVOGADO);
      return;
    } else if (roles.includes(UserRolesEnum.CLOSER_INTERNO)) {
      populateCloserDropdownByRole(UserRolesEnum.CLOSER_INTERNO);
      return;
    }

    populateCloserDropdownByRole(userInfo?.roles[0]?.name);
  };

  const handleChangeTipoLegal = (value) => {
    setSelectedLegalType(value);
    populateClosersDropdown(value);
  };

  const getTipoLegal = () => {
    const roleName = userInfo?.roles[0]?.name;

    if (roleName === UserRolesEnum.SUPER_ADMIN) {
      return (
        <Form.Item name="tipoLegal" label="Tipo legal do acordo:">
          <Select
            size="large"
            onChange={(value) => handleChangeTipoLegal(value)}
            optionLabelProp="title"
            defaultValue={'extra_judicial'}
            value={selectedLegalType}>
            {legalTypes.length &&
              legalTypes.map((type) => (
                <Select.Option key={uuid()} title={String(type).replace('_', '-')} value={type}>
                  <div className="label-type">{String(type).replace('_', '-')}</div>
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      );
    } else if (roleName === UserRolesEnum.CLOSER_INTERNO_ADVOGADO) {
      return (
        <Form.Item name="tipoLegal" label="Tipo legal do acordo:">
          <Select
            defaultValue="judicial"
            disabled
            options={[
              {
                value: 'judicial',
                label: 'Judicial'
              }
            ]}
          />
        </Form.Item>
      );
    } else if (roleName === UserRolesEnum.CLOSER_INTERNO) {
      return (
        <Form.Item name="tipoLegal" label="Tipo legal do acordo:">
          <Select
            defaultValue="extra_judicial"
            disabled
            options={[
              {
                value: 'extra_judicial',
                label: 'Extra Judicial'
              }
            ]}
          />
        </Form.Item>
      );
    }
  };

  const handleSelectedCloser = (value) => {
    setSelectedCloser(value);
  };

  return (
    <Form
      form={form}
      name="custom-form"
      onFinish={handleCreateAgreement}
      initialValues={initialValues}>
      <Form.Item name="contrato_id" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="contrato" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="cpfCnpj" hidden>
        <Input />
      </Form.Item>

      <Row gutter={24}>
        <Col span={12}>{getTipoLegal()}</Col>
      </Row>

      <Row gutter={[8, 8]}>
        <Col span={6}>
          <Form.Item name="closer">
            <Select
              defaultValue={
                getBestUserRole() === UserRolesEnum.SUPER_ADMIN ? 'closer' : userInfo.name
              }
              onChange={handleSelectedCloser}
              disabled={disableDropdownByRole()}>
              <Option value="closer">Closer</Option>
              {closerList.map((closer, index) => {
                return (
                  <Select.Option key={uuid()} title={closer.nome} value={closer.id}>
                    <div className="label-type">{closer.name}</div>
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item name="srd">
            <Select defaultValue={'sdr'} onChange={handleSelectedSDR}>
              <Option value="sdr">SDR</Option>
              {sdrList.map((sdr, index) => {
                return (
                  <Select.Option key={uuid()} title={sdr.name} value={sdr.id}>
                    <div className="label-type">{sdr.name}</div>
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col span={12}>
          {/* Col span={8} */}
          <div>
            <p>Valor total dos contratos selecionados</p>
            <InputNumber disabled addonBefore="R$" value={totalSelectedValues} />
          </div>
        </Col>

        <Col span={12}>
          {/* Col span={8} */}
          <div>
            <p>Total dos dias em atraso</p>
            <InputNumber disabled addonBefore={<CalendarOutlined />} value={diasEmAtraso} />
          </div>
        </Col>

        {/* <Col span={3}>
          <div className='collum-center' hidden>
            <p>Comissão</p>
            <InputNumber disabled addonBefore="%" onChange={onChange} defaultValue={0} />
          </div> 
        </Col> */}
      </Row>

      <Row style={{ marginTop: 24 }}>
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
          <Form.Item name="hasContratos">
            <p>Contratos a serem incluídos no acordo:</p>
            {contractList.length > 0 && (
              <>
                <p>
                  <Checkbox.Group>
                    <Checkbox key={uuid()} onChange={handleSelectAllContracts}>
                      Selecionar todos
                    </Checkbox>
                  </Checkbox.Group>
                </p>
                {renderContractsCheckbox(contractList)}
              </>
            )}
          </Form.Item>
        </Space>
      </Row>

      <Row gutter={[8, 8]}>
        <Col span={7}>
          <Form.Item>
            <p>Melhor email</p>
            <Input placeholder="Email atualizado" onChange={handleBestEmail} value={bestEmail} />
          </Form.Item>
        </Col>

        <Col span={7}>
          <Form.Item>
            <p>Emails cadastrados</p>
            <Select defaultValue={contrato?.email} onChange={handleChangeEmailsCadastrados}>
              <Option value={contrato.devedor?.email}>{contrato.devedor?.email}</Option>
              {contrato.devedor?.email_acordoeasy && (
                <Option value={contrato.devedor?.email_acordoeasy}>
                  (AcordoEasy) {contrato.devedor?.email_acordoeasy}
                </Option>
              )}
              {contrato.devedor?.email2 && (
                <Option value={contrato.devedor?.email2}>{contrato.devedor?.email2}</Option>
              )}
              {contrato.devedor?.email3 && (
                <Option value={contrato.devedor?.email3}>{contrato.devedor?.email3}</Option>
              )}
              {contrato.devedor?.email4 && (
                <Option value={contrato.devedor?.email4}>{contrato.devedor?.email4}</Option>
              )}
            </Select>
          </Form.Item>
        </Col>

        <Col span={5}>
          <Form.Item>
            <p>Melhor telefone</p>
            <Input placeholder="Telefone atualizado" onChange={handleBestPhone} value={bestPhone} />
          </Form.Item>
        </Col>

        <Col span={5}>
          <Form.Item>
            <p>Telefones cadastrados</p>
            <Select
              defaultValue={
                contrato.devedor?.telefoneResidencial
                  ? contrato.devedor?.dddResidencial + ' ' + contrato.devedor?.telefoneResidencial
                  : ''
              }
              onChange={handleChangeTelefonesCadastrados}>
              <Option
                value={
                  contrato.devedor?.telefoneResidencial
                    ? contrato.devedor?.dddResidencial + ' ' + contrato.devedor?.telefoneResidencial
                    : ''
                }>
                {contrato.devedor?.telefoneResidencial
                  ? contrato.devedor?.dddResidencial + ' ' + contrato.devedor?.telefoneResidencial
                  : ''}
              </Option>
              {contrato.devedor?.telefone1 && (
                <Option value={contrato.devedor?.telefone1}>{contrato.devedor?.telefone1}</Option>
              )}
              {contrato.devedor?.telefone2 && (
                <Option value={contrato.devedor?.telefone2}>{contrato.devedor?.telefone2}</Option>
              )}
              {contrato.devedor?.celular1 && (
                <Option value={contrato.devedor?.celular1}>{contrato.devedor?.celular1}</Option>
              )}
              {contrato.devedor?.celular2 && (
                <Option value={contrato.devedor?.celular2}>{contrato.devedor?.celular2}</Option>
              )}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <div className="form-box">
        <div className="form-basic-info">
          <Row gutter={[8, 8]}>
            <Col span={12}>
              {/* Col span={9} */}
              <Form.Item name="valorTotalAcordo">
                <p>Valor total do acordo</p>
                <InputNumber
                  addonBefore="R$"
                  value={valorTotalAcordo}
                  onChange={handleValorTotalAcordoChange}
                  onKeyDown={handleValorTotalAcordoKeyDown}
                  formatter={(value) =>
                    `${parseFloat(value).toLocaleString('pt-BR', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}`
                  }
                  parser={(value) => value.replace(/\D/g, '') / 100}
                />
              </Form.Item>
            </Col>

            {/* <Col span={12}>
              <Form.Item name="qtdParcelas">
                <div className="collum-center">
                  <p>Quantidade de Parcelas</p>
                  <InputNumber
                    disabled
                    addonBefore={<ProfileOutlined />}
                    value={dataInstallment.length}
                  />
                </div>
              </Form.Item>
            </Col> */}

            {/* <Col span={9}>
              <Form.Item name="valorTotalComissao" hidden>
                <div className='collum-center'>
                  <p>Valor total da comissão</p>
                  <InputNumber disabled addonBefore="R$" value={0} />
                </div>
              </Form.Item>
            </Col> */}
          </Row>
        </div>

        <Divider />

        <div className="form-installments">
          <h3>segmento parcela</h3>
          {segmentosParcela.map((segmento, index) => (
            <Row gutter={[8, 8]} align="middle" key={index}>
              <Col span={2}>
                <Form.Item name="de">
                  <div className="collum-center">
                    <p>de</p>
                    <InputNumber disabled defaultValue={segmento.de} />
                  </div>
                </Form.Item>
              </Col>

              <Col span={2}>
                <Form.Item name="ate">
                  <div className="collum-center">
                    <p>até</p>
                    <InputNumber
                      min={segmento.de}
                      defaultValue={segmento.ate}
                      onChange={(value) => handleAteChange(index, value)}
                      disabled={index < segmentosParcela.length - 1 && 'disabled'}
                    />
                  </div>
                </Form.Item>
              </Col>

              <Col span={2}>
                <p>parcelas</p>
              </Col>

              <Col span={8}>
                <Form.Item name="valorParcela">
                  <div className="collum-center">
                    <p>Valor Parcela</p>
                    <InputNumber
                      addonBefore="R$"
                      value={segmento.valorParcela}
                      onKeyDown={handleValorTotalAcordoKeyDown}
                      parser={(value) => value.replace(/\D/g, '') / 100}
                      onChange={(value) => {
                        const newSegmentosParcela = [...segmentosParcela];
                        newSegmentosParcela[index].valorParcela = parseFloat(value);
                        setSegmentosParcela(newSegmentosParcela);
                      }}
                      formatter={(value) =>
                        `${parseFloat(value).toLocaleString('pt-BR', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}`
                      }
                    />
                  </div>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item>
                  <div className="collum-center">
                    {segmentosDatePickerStatus[index] === 'error' ? (
                      <Tooltip
                        title="Data de vencimento anterior a alguma data existente."
                        color="red">
                        <p style={{ color: 'red' }}>Vencimento</p>
                      </Tooltip>
                    ) : (
                      <p>Vencimento</p>
                    )}
                    <DatePicker
                      ref={inputElement}
                      allowClear={false}
                      defaultValue={selectedDueDate[index]}
                      format={dateFormat}
                      locale={locale}
                      disabledDate={disabledDate}
                      value={selectedDueDate[index]}
                      onChange={(date) => handleDueDateChange(date, index)}
                      placeholder="Selecione"
                    />
                  </div>
                </Form.Item>
              </Col>

              {index === segmentosParcela.length - 1 && index > 0 ? (
                <Col span={2}>
                  <a className="delete-installments" onClick={() => excluirSegmentoParcela(index)}>
                    <DeleteOutlined />
                  </a>
                </Col>
              ) : (
                <Col span={2} />
              )}
            </Row>
          ))}
          <Divider />

          <Row justify="end">
            <Col>
              <a className="add-installments" onClick={adicionarSegmentoParcela}>
                <PlusOutlined /> Adicionar segmento parcela
              </a>
            </Col>
          </Row>
          <Divider />

          <Row justify="end">
            <Col>
              <Form.Item name="qtdParcelas">
                <div className="collum-center">
                  <p>Total de Parcelas Cadastradas</p>
                  <InputNumber disabled addonBefore={<ProfileOutlined />} value={totalParcelas} />
                </div>
              </Form.Item>
            </Col>
          </Row>
        </div>

        <Divider />
        <Row>
          <Col span={24} style={{ textAlign: 'center' }}>
            <div className="form-footer">
              <Button onClick={handleCloseModal} style={{ margin: '0 8px' }}>
                Cancelar
              </Button>
              <Button
                className="btn-darkblue"
                onClick={handlePreviewClick}
                disabled={!isDataValid}
                loading={isLoading}>
                {isLoading ? 'Aguarde...' : 'PRÉ VISUALIZAR PARCELAMENTO'}
              </Button>
            </div>
          </Col>
        </Row>

        <Modal
          visible={isModalOpen}
          footer={null}
          onCancel={handleCancel}
          width={800}
          height="auto"
          bodyStyle={{ display: 'flex', flexDirection: 'column' }}>
          <div className="view-installment-box">
            <Table
              bordered
              columns={previewInstallment}
              dataSource={dataInstallment}
              rowKey={() => uuid()}
              pagination={false}
              size="small"
              title={() => 'PRÉ VISUALIZAÇÃO DO PARCELAMENTO'}
              footer={() => (
                <Table
                  columns={InstallmentInfo}
                  dataSource={installmentTotal}
                  pagination={false}
                  bordered
                />
              )}
            />

            <Row>
              <Col span={24} style={{ textAlign: 'center' }}>
                <div className="view-installment-buttons">
                  <Button onClick={handleCancel} style={{ margin: '0 8px' }}>
                    Cancelar
                  </Button>
                  <Button type="primary" onClick={handleGenerateSimulation} loading={isLoading}>
                    {isLoading ? 'Aguarde...' : 'Gerar simulação'}
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </Modal>
      </div>
    </Form>
  );
};

export default FormularioPersonalizado;
