import { AxiosRequestConfig } from 'axios';
import AxiosHttpClient from './AxiosHttpClient';

class BaseHttpClient extends AxiosHttpClient {
  constructor() {
    super();
    this.instance.interceptors.request.use(async (config: AxiosRequestConfig) => {
      config.baseURL = process.env.REACT_APP_URL_API;
      return config;
    });
  }
}

export default BaseHttpClient;
