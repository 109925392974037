import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';

import ContractClient from '../../client/api';

import locale from 'antd/es/date-picker/locale/pt_BR';

import {
  Divider,
  DatePicker,
  Table,
  Button,
  Descriptions,
  Row,
  Col,
  Modal,
  message as antMessage
} from 'antd';

const InstallmentsList = ({ acordo, dataContrato, salvarAcordo }) => {
  const {
    parcelas,
    dataAcordo,
    valorAcordado,
    error,
    message,
    descontoPerc,
    descontoValor,
    valorParcela,
    valorEntrada,
    valorSinal,
    idAcordo,
    nParcelas
  } = acordo;

  const client = new ContractClient();

  const [modalConfirmAcordo, setModalConfirmAcordo] = useState(false);
  const [redirectAcordo, setRedirectAcordo] = useState(false);

  const [errorSave, setErrorSave] = useState('');
  const [successSave, setSuccessSave] = useState(false);

  if (error) {
    return <div>{message}</div>;
  }

  const success = (msg) => {
    antMessage.success(msg);
  };

  const setParcelaPaga = (dataPagamento, idParcela) => {
    if (dataPagamento) {
      const setResponse = client.updateParcelaByIdParcela(dataPagamento, idParcela);
      if (setResponse) {
        success(`Parcela ${idParcela} atualizada para em ${dataPagamento}`);
      }
    }

    return true;
  };

  const columns = [
    {
      title: '',
      dataIndex: 'numeroParcela'
    },
    {
      title: 'Vencimento',
      dataIndex: 'dataParcela'
    },
    {
      title: 'Valor parcela',
      dataIndex: 'valorParcela'
    },
    {
      title: 'Pagamento',
      render: (_, record) => {
        return (
          <p style={{ textAlign: 'center', margin: 0 }}>
            <DatePicker
              size="large"
              format={'DD/MM/YYYY'}
              locale={locale}
              placeholder="Selecione"
              onChange={(_, dateString) => {
                setParcelaPaga(dateString, record.idParcela);
              }}
            />
          </p>
        );
      }
    }
  ];

  const handleCancel = () => {
    setModalConfirmAcordo(false);
  };

  const handleSave = async () => {
    const { success: successSave, error: errorSave, message } = await salvarAcordo(idAcordo);
    setErrorSave(errorSave);
    setSuccessSave(successSave);
    setModalConfirmAcordo(true);
  };

  if (redirectAcordo) {
    return <Navigate to={`/acordo/${idAcordo}`} />;
  }

  return (
    <>
      <h2>Simulação de acordo</h2>
      <div className="no-print">
        <p>Salve esta simulação para efetivar o acordo</p>
        <Button
          type="primary"
          onClick={() => {
            document.querySelector('#simulador').scrollIntoView({ behavior: 'smooth' });
          }}>
          Simular novamente
        </Button>

        <Divider />
      </div>
      <Descriptions title="Resumo do acordo" column={3}>
        <Descriptions.Item label="Data do acordo">{dataAcordo}</Descriptions.Item>
        <Descriptions.Item label="Número de parcelas">{nParcelas}</Descriptions.Item>
        <Descriptions.Item label="Valor acordado">R$ {valorAcordado}</Descriptions.Item>
        {descontoPerc && (
          <Descriptions.Item label="Percentual de desconto">{descontoPerc}%</Descriptions.Item>
        )}
        {descontoValor && (
          <Descriptions.Item label="Desconto">R$ {descontoValor}</Descriptions.Item>
        )}
        {valorParcela && (
          <Descriptions.Item label="Valor parcela">R$ {valorParcela}</Descriptions.Item>
        )}
        {valorEntrada && (
          <Descriptions.Item label="Valor entrada">R$ {valorEntrada}</Descriptions.Item>
        )}
        {valorSinal && <Descriptions.Item label="Valor sinal">R$ {valorSinal}</Descriptions.Item>}
      </Descriptions>
      <Divider />
      <div className="pagebreak" />

      <h3 className="mt20-print">Parcelas</h3>
      <Table columns={columns} dataSource={parcelas} pagination={false} bordered size="small" />

      <Modal
        title={`Acordo ${idAcordo}`}
        visible={modalConfirmAcordo}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={() => setModalConfirmAcordo(false)}>
            Fechar
          </Button>,
          successSave && (
            <Button key="submit" type="primary" onClick={() => setRedirectAcordo(true)}>
              Ver o acordo
            </Button>
          )
        ]}>
        <div className="historico-geracao">
          {successSave ? (
            <>
              <h4>{message}</h4>
              <p>Abra o acordo e gere os boletos.</p>
            </>
          ) : (
            <p>{errorSave}</p>
          )}
        </div>
      </Modal>

      <Divider />

      <Row gutter={8} className="no-print">
        <Col span={24} style={{ textAlign: 'center', paddingBottom: '40px' }}>
          <>
            <Button
              type="secondary"
              onClick={() => {
                document.title = `Simulação-de-acordo-contrato-${Number(dataContrato?.contrato)}`;
                window.print();
              }}>
              Imprimir simulação
            </Button>

            {idAcordo && (
              <Button
                style={{ margin: '0 8px' }}
                type="primary"
                htmlType="submit"
                onClick={() => handleSave()}>
                Efetivar acordo
              </Button>
            )}
          </>
        </Col>
      </Row>
    </>
  );
};

export default InstallmentsList;
