import React, { useEffect, useState } from 'react';

import { Layout, Breadcrumb, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

import Contratos from '../views/Contratos';
import ContractClient from '../client/api';

const { Content } = Layout;

function ContratosPage() {
  const [data, setData] = useState();

  const client = new ContractClient();

  async function getContracts(page, limit, searchTerms) {
    const data = await client.getContracts(page, limit, searchTerms);
    setData(data);
  }

  useEffect(() => {
    getContracts();
  }, []);

  return (
    <Layout>
      <Breadcrumb>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Lista de contratos</Breadcrumb.Item>
      </Breadcrumb>
      <Content
        className="site-layout-background"
        style={{
          padding: 0,
          margin: 0,
          minHeight: 280,
          background: '#fff'
        }}
      >
        {data ? (
          <Contratos data={data} onChange={getContracts} />
        ) : (
          <div className="loadingWrapper">
            <Spin indicator={antIcon} />
            <p>Carregando...</p>
          </div>
        )}
      </Content>
    </Layout>
  );
}

export default ContratosPage;
