import BaseHttpClient from '../http/AxiosHttpClient';

export class UserApi {
  private readonly _httpClient: BaseHttpClient;

  public constructor() {
    this._httpClient = new BaseHttpClient();
  }

  public async getUserListByRole(role: string){
    const uri = `/system/api/1.0/user/get_by_role/${role}`;
    return this._httpClient.get(uri);
  }

  public async getSDRListByCloser(){
    const uri = `/system/api/1.0/user/get_sdr_by_logged_user`;
    return this._httpClient.get(uri);
  }

}