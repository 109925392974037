import { useEffect, useState } from 'react';

import { Layout, Breadcrumb, Spin, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const { Content } = Layout;

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

import ContractClient from '../client/api';
import { Api } from '../client/lib/api';
import { HANDLER_ERRORS } from '../errors';
import AcordosList from 'components/AcordosList';
import { AcordosFilter } from 'components/AcordosFilter';


function AcordosPage() {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState([]);

  const client = new ContractClient();

  async function getAcordos() {
    setLoading(true);
    await Api()
      .agreements.getAgreements(filters)
      .then((response) => {
        setData(response.data);
      })
      .catch((err) => {
        console.error(err);
        message.error(HANDLER_ERRORS.AGREEMENTS.LIST_ERROR);
      });
    setLoading(false);
  }


  async function changePage(page, limit, submitFilters) {
    filters.page=page;
    setFilters(structuredClone(filters)); 
  }

  const changeAcordoStatus = async (idAcordo, idStatus) => {
    Api()
      .agreements.updateStatus(idAcordo, idStatus)
      .then(() => {
        return data;
      });
  };

  useEffect(() => {
    getAcordos();
  }, [filters]);

  async function onFilterAcordos(searchTerms) {
    const filtersAcordos = { ...searchTerms, page: 1 };
    const carteira = localStorage.getItem('carteira');
    if (carteira !== '0') {
      filtersAcordos.carteira = carteira;
    }
    setFilters(filtersAcordos); 
  }

  return (
    <Layout>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/acordos">Lista de acordos</Breadcrumb.Item>
      </Breadcrumb>

      <Content className="site-layout-background">
        <>
          <AcordosFilter onSubmitSearch={onFilterAcordos} />
          {loading ? (
            <div className="loadingWrapper">
              <Spin indicator={antIcon} />
              <p>Carregando</p>
            </div>
          ) : (
            data && (
              <AcordosList
                data={data}
                onChange={changePage}
                changeAcordoStatus={changeAcordoStatus}
              />
            )
          )}
        </>
      </Content>
    </Layout>
  );
}

export default AcordosPage;
